import React from 'react'
import Sidebar from '../../../Side'
import Navigation from '../../../Navigation'
import PopularVideo from './PopularVideo'
const index = () => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("user")
    const pageTitle = 'Video';
    const active = 'active';
    if(token){
    return (
        <>
             <div className="main-parentcontainer">
          <div className="grid-parent">
          <Sidebar data={name} pgc={active} />
            <div className="grid-child">
            <Navigation data={pageTitle} />
            <PopularVideo />
            </div>{" "}
          </div>
            </div>
            
           
           
        </>
    )}
    else{
        window.location.href = '/'
    }
}

export default index
