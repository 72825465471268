import React, { useState, useEffect, useRef, createRef } from "react";
import axios from "axios";
import Navigation from "../../Navigation";
import Sidebar from "../../Side";
import EnglishBlog from "./EnglishBlog";

const Index = () => {
  const token = localStorage.getItem("token");

  const pageTitle = "English Blog ";
  const active = "active";
  const name = "Admin";

  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <EnglishBlog />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
