import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PieChart from '../../PieChart'
import BarChart from '../../BarChart'
import InfiniteScroll from "react-infinite-scroller";
import Form from '../../Form'
import { useNavigate } from 'react-router-dom'
import { Table } from "reactstrap";
import { Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const img = require('../../../assets/img/profile_pic.jpg');
const url = process.env.REACT_APP_BASE_URL;



const Index = (props) => {
  const navigate = useNavigate()
  const [stats, setStats] = useState({});
  const [barData, setBarData] = useState([]);
  const [day, setDay] = useState(7);
  const [newUserList, setNewUserList] = useState([])
  const data1 = { title: 'Monthly Revenue', totalRevenue: 'Rs. 3,08,255.17 crore.' }
  let x = stats.male_ratio;
  let y = stats.female_ratio;
  let z = stats.other_ratio;
  let total = x + y + z;
  const percentagemaleRatio = parseInt((x * 100) / total)
  const percentagefemaleRatio = parseInt((y * 100) / total)
  const percentageotherRatio = 100 - (percentagemaleRatio + percentagefemaleRatio)

  let data = [
    { value: percentagemaleRatio },
    { value: percentagefemaleRatio },
    { value: percentageotherRatio }
  ];

  const ApiCall = () => {
    axios.get(url + `/admin/dashboard?days=${day}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Accept': 'application/json',
"ngrok-skip-browser-warning": "any",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setStats(response.data.stats);
      setNewUserList(response.data.newUserList)
      setBarData(response.data.userGraph)
    });
  }

  useEffect(() => {
    ApiCall()
  }, [day]);
  return (
    <section className='postCount'>
      <ul className='ulList flex'>
        <div>
          <li style={{cursor: 'pointer'}}>
            New Accounts
          </li>
          <li className='listItem'>
            <Navbar light expand="lg">
              <Nav navbar>
                <UncontrolledDropdown setActiveFromChild>
                  <DropdownToggle tag="b" caret className='newUserDropdown'>
                    {stats.new_accounts}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" onClick={() => { setDay(7) }}>Last 7 Days</DropdownItem>
                    <DropdownItem tag="a" onClick={() => { setDay(15) }}>Last 15 Days</DropdownItem>
                    <DropdownItem tag="a" onClick={() => { setDay(30) }}>Last 30 Days</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Navbar>
          </li>
        </div>
        <div>
          <li>
            Total Accounts
          </li>
          <li className='listItem'>
            <span className='newAccounts'>{stats.total_accounts}</span>
          </li>
        </div>
        <div>
          <li>
            Total Post
          </li>
          <li className='listItem'>
            <span className='newAccounts'>{stats.total_media_post}</span>
          </li>
        </div>
        <div>
          <li>
            Total Polls
          </li>
          <li className='listItem'>
            <span className='newAccounts'>{stats.total_polls}</span>
          </li>
        </div>
        <div>
          <li>
            Total Shorts
          </li>
          <li className='listItem'>
            <span className='newAccounts'>{stats.total_shorts}</span>
          </li>
        </div>
      </ul>
      <div className='flexI'>
        <div className='newUserGraphContainer'>
          <div className='flexContainer'>
            <div className='flexItemI'>{data1.title}</div>
            <div className='flexItemII'>{data1.totalRevenue}</div>
          </div>
          <div className='userLast'>
            <h5>Users in Last 30 days</h5>
            <div className='userGraph'>
              <div className='graph'>
                <BarChart data={barData}/>
              </div>
              <p>Users per day</p>
            </div>
          </div>
          <div className='userLast'>
            <h5>Total Users</h5>
            <div className='countBox'>
              {data[0].value && <PieChart states={stats} data={data} />}
            </div>
          </div>
        </div>
        <div className='newUserContainer'>
          <Navbar light expand="lg" className='newUserDropdown'>
            <Nav navbar>
              <b>New Users in last {day} days</b>
            </Nav>
          </Navbar>
          <Form  data={newUserList}/>
          
        </div>
      </div>
    </section>
  )
}

export default Index
