import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "./Components/RichTextEditor";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";


import "./App.css";

const Editor = () => {
  const token = localStorage.getItem("token");
  const history = useLocation();
  const [data, setData] = useState();
  const [language, setLanguage] = useState();
  const [value, setValue] = useState("");
  const getValue = (value) => {
    String(value);
    if (value.includes("'")) {
      const textEditorBox = document.getElementById("textEditorBox");
      textEditorBox.style.color = "red";
      popUp();
    } else {
      const textEditorBox = document.getElementById("textEditorBox");
      setValue(value);
      textEditorBox.style.color = "black";
    }
  };

  function popUp() {
    alert(`Please remove ( ' )single Quote`);
  }
  if (token && history.state != null) {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <textarea
            className="scroll"
            style={{ width: "50%", height: "100vh" }}
            value={value}
          ></textarea>
          <div id="textEditorBox" style={{ width: "50%", height: "90vh" }}>
            <RichTextEditor
              getValue={getValue}
              data={
                // history.state.language == 'hi' ? history.state.content.content_hi :
                history.state.content?history.state.content.content_en:""}
              setData={setData}
              language={history.state.language}
              type={history.state.type}
              state={history.state}
            />
          </div>
        </div>
      </div>
    )
  }
  else {
    window.location.href = '/'
  }
};

export default Editor;
