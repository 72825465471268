import React, { Component } from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import { Collapse } from 'reactstrap';
import AddCategoryForm1 from '../AddCategory/addCategoryForm';
import { useFormik } from 'formik';
import * as Yup from "yup";
import {
    FormGroup, Label, Input,
    Form
} from 'reactstrap';
import { patch } from 'axios';
const url = process.env.REACT_APP_BASE_URL

// const AddCategoryForm = () => {
const Example = () => {
    const addCategoryForm = async (values) => {
        try {
            const formdata = new FormData();
            for (let key in values) {
                formdata.append(key, values[key]);
            }
            const res = await patch(url + "/adminRgyan/get", formdata,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
        } catch (err) {
            console.log("Error::::::::", err);
        }
    }


    const formik = useFormik({
        initialValues: {
            name: '',
            color: '',
            image: '',
            parent_id: '',
            is_special: false,

        },
        onSubmit: (values) => {
            addCategoryForm(values);
        }
        , validationSchema: () =>
            Yup.object().shape({
                name: Yup.string()
                    .required("Category name is required")
                    .min(3, "Please enter you full name."),
                color: Yup.string().required("Category color is required"),
                image: Yup.string()
                    .required("Category image is required"),
                parent_id: Yup.string().required("Parent ID is required"),
            }),
    })

    const inputError = (formik, input) => {
        return (
            formik.errors[input] &&
            formik.touched[input] && (
                <div
                    style={{
                        color: "rgb(235, 54,54)",
                        fontSize: "14px",
                    }}
                >
                    {formik.errors[input]}
                </div>
            )
        );
    };



    return (
        <section className='postCountIV' style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>

            <Button id="toggler" style={{ marginBottom: '1rem', width: '100%', backgroundColor: 'white', border: 'none', boxShadow: '0px 4px 4px rgb(253 126 20 / 40%) ', color: 'black', fontWeight: 'bold' }}>
                Add Category
            </Button>
            <UncontrolledCollapse toggler="#toggler">
                <Card>
                    <CardBody>
                        <AddCategoryForm1 />
                    </CardBody>
                </Card>
            </UncontrolledCollapse>
            <Button color="primary" id="togglerr" style={{ marginBottom: '1rem', width: '100%', backgroundColor: 'white', border: 'none', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)', color: 'black' }}>
                Add Sub Category
            </Button>
            <UncontrolledCollapse toggler="#togglerr">
                <Card>
                    <CardBody style={{ overflow: 'scroll', overflowY: 'scroll', overflowX: 'hidden', height: '100%' }}>
                        <div style={{
                            display: 'block', width: 1100, padding: '0px 20px 0px 20px'
                        }}>
                            <Form onSubmit={formik.handleSubmit}>
                                <h5>Add Sub Category</h5>
                                <FormGroup>
                                    <Label for="name" className='lableCategory'>Name:</Label>
                                    <Input type="name" name="name" id="name" placeholder={`Enter Category Name`} onChange={formik.handleChange} />
                                    {inputError(formik, "name")}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="color" className='lableCategory'>Color:</Label>
                                    <Input type="color" name="color" id="color" onChange={formik.handleChange} />
                                    {inputError(formik, "color")}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="image" className='lableCategory'>Image:</Label>
                                    <Input type="file" name="image" id="image" placeholder={` Image`} onChange={(event) => {
                                        formik.setFieldValue(
                                            'image',
                                            event.currentTarget.files[0]
                                        );
                                    }} />
                                    {inputError(formik, "image")}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="radio" className='lableCategory'>Special:</Label><br />
                                    <Input type="radio" name="is_special" id="radio" value={true} placeholder={`Change Category Image`} onChange={formik.handleChange} />{" True "}
                                    <Input type="radio" name="is_special" id="radio" value={false} placeholder={`Change Category Image`} onChange={formik.handleChange} />{" False "}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exampleSelect" className='lableCategory'>Parent Category:</Label>
                                    <Input type="select" name="parent_id" id="exampleSelect" onChange={formik.handleChange}>
                                        <option value='1'>Astrology</option>
                                        <option value='7'>Suvichar</option>
                                        <option value='15'>Holy Places</option>
                                        <option value='23'>Health</option>
                                        <option value='33'>Spiritual</option>
                                        <option value='39'>Arts & Society</option>
                                        <option value='45'>Festival</option>
                                    </Input>
                                    {inputError(formik, "parent_id")}
                                </FormGroup>
                                <Button style={{ display: 'inline-block', width: "200px", float: 'right' }} type="submit"><strong>Add Sub Category</strong></Button>
                            </Form>
                        </div >
                    </CardBody>
                </Card>
            </UncontrolledCollapse>
        </section>
    );
}
    ;

export default Example;