import React, { useState } from "react";

import { createElement } from "react";
import Navigation from "../../Navigation";
import Sidebar from "../../Side";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ButtonToggle,
} from "reactstrap";
import Select from "react-select";
const index = () => {
  // basic page info
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Festivals ";
  const active = "active";
const url =process.env.REACT_APP_BASE_URL
  // for data pass and get
  const history = useLocation();
//   console.log(history, "l;l;");
  const navigate = useNavigate();
  const [selmonth, setselmonth] = useState(history.state.month);

  // state management and true false
  const [visible, setVisible] = useState(true);
  const [stage2, setStage2] = useState(false);
  const [formdata, setformdata] = useState({
    nameEn: "",
    nameHi: "",
    slug: "",
    month: "",
    descriptionEn: "",
    descriptionHi: "",
    imageUrl: "",
    festivalDate: "",
  });
  const [formdata2, setformdata2] = useState({
    titleEn: "",
    titleHi: "",
    contentEn: "",
    contentHi: "",
    festivalSubCategoryImage: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  // functions
  const onDismiss = () => setVisible(false);
  const handleupdate = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, ";l;");
    setformdata((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleupdate2 = (e) => {
    const { name, value } = e.target;
//     console.log(name, value, ";l;");
    setformdata2((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var date = new Date(formdata.festivalDate);
    var day = date.getDate();
    const data = {
      nameHi: formdata.nameHi,
      nameEn: formdata.nameEn,
      slug: formdata.slug,
      descriptionEn: formdata.descriptionEn,
      descriptionHi: formdata.descriptionHi,
      imageUrl: formdata.imageUrl,
      month: selmonth,
      festivalDate: day,
    };
    // console.log(data);
    setStage2(true);
    //     axios
    //       .post(
    //         `https://c9e0-2401-4900-1cd7-c0d3-5157-faba-4493-8115.ngrok-free.app/admin/add-festival-category`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem("token")}`,
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             "ngrok-skip-browser-warning": "any",
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         console.log(res, "this is res");
    //         if (res.data.code === 200) {
    //           setIsSuccess(true);
    //           setMessage("Festival category added Successfully");
    //           setTimeout(() => {
    //             setIsSuccess(false);
    //             setMessage("");
    //             navigate(-2);
    //           }, 3000);
    //         }
    //       })
    //       .catch((Err) => {
    //         console.log("Error::::::::", Err);
    //         setIsError(true);
    //         setError(Err.
    //           response.data.msg
    //         );
    //       });
  };
  const handleSubmit2 = (e) => {
    e.preventDefault();
    var date = new Date(formdata.festivalDate);
    var day = date.getDate();
    const data = {
      nameHi: formdata.nameHi,
      nameEn: formdata.nameEn,
      slug: formdata.slug,
      descriptionEn: formdata.descriptionEn,
      descriptionHi: formdata.descriptionHi,
      imageUrl: formdata.imageUrl,
      month: selmonth,
      parentId:history.state.id,                                                                                    festivalDate: day,
      titleHi:formdata2.titleHi,
      festivalDetailImage:formdata2.festivalSubCategoryImage,
      contentEn:formdata2.contentEn,
      contentHi:formdata2.contentHi,
      titleEn:formdata2.titleEn,
    };
    // console.log(data);
    setStage2(true);
        axios
          .post(
            `/admin/add-festival-sub-category`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "any",
              },
            }
          )
          .then((res) => {
            // console.log(res, "this is res");
            if (res.data.status === "Success") {
              setIsSuccess(true);
              setMessage("Festival Sub category added Successfully");
              setTimeout(() => {
                setIsSuccess(false);
                setMessage("");
                navigate(-1);
              }, 3000);
            }
          })
          .catch((Err) => {
            // console.log("Error::::::::", Err);
            setIsError(true);
            setError(Err.
              response.data.msg
            );
          });
  };

  // required data
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const defaultvalue = {
    value: history.state.month,
    label: months[history.state.month - 1],
  };
  const selectOption = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const URL = process.env.REACT_APP_BASE_URL;

  if (token && selmonth) {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <div>
                <div className="alert-side">
                  {isSuccess && (
                    <Alert color="info" isOpen={visible} toggle={onDismiss}>
                      {message}
                    </Alert>
                  )}
                </div>
                <div className="alert-side">
                  {" "}
                  {isError && (
                    <Alert
                      style={{
                        background: "rgb(251, 99, 71)",
                        color: "white",
                      }}
                      isOpen={visible}
                      toggle={onDismiss}
                    >
                      {error}
                    </Alert>
                  )}
                </div>
                {!stage2 && (
                  <p className="name-add">
                    Add new festival sub category in {history.state.name_en}
                  </p>
                )}
                {!stage2 && (
                  <form onSubmit={handleSubmit}>
                    <div className="form">
                      <div className="each-input">
                        <label>Name En*</label>
                        <input
                          required
                          type="text"
                          value={formdata.nameEn}
                          name="nameEn"
                          onChange={handleupdate}
                          placeholder="add festival name in English"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Name Hi*</label>
                        <input
                          required
                          value={formdata.nameHi}
                          name="nameHi"
                          onChange={handleupdate}
                          type="text"
                          placeholder="add festival name in Hindi"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Slug*</label>
                        <input
                          required
                          value={formdata.slug}
                          name="slug"
                          onChange={handleupdate}
                          type="text"
                          placeholder="Cretae a unique slug"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Description En</label>
                        <input
                          //                         required
                          value={formdata.descriptionEn}
                          name="descriptionEn"
                          onChange={handleupdate}
                          placeholder="add description in English"
                          type="text"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Description Hi</label>
                        <input
                          //                         required
                          value={formdata.descriptionHi}
                          name="descriptionHi"
                          onChange={handleupdate}
                          placeholder="add description in Hindi"
                          type="text"
                        ></input>
                      </div>

                      <div  className="each-input">
                        <label>Month*</label>
                        <Select
                         id="slect-month"
                          defaultValue={defaultvalue}
                          isDisabled={true}
                          options={selectOption}
                        />
                      </div>

                      <div className="each-input">
                        <label>Festival Date*</label>
                        <input
                          required
                          value={formdata.festivalDate}
                          name="festivalDate"
                          onChange={handleupdate}
                          type="date"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Image url*</label>
                        <input
                          required
                          value={formdata.imageUrl}
                          name="imageUrl"
                          onChange={handleupdate}
                          placeholder="add your image url here"
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="add-f-parent2">
                      <button type="submit" className="add-festivel">
                        Next
                      </button>
                    </div>
                  </form>
                )}
                {stage2 && (
                  <p className="name-add">
                   Add new festival sub category in {history.state.name_en}
                  </p>
                )}
                {stage2 && (
                  <form onSubmit={handleSubmit2}>
                    <div className="form">
                      <div className="each-input">
                        <label>Title En*</label>
                        <input
                          required
                          type="text"
                          value={formdata2.titleEn}
                          name="titleEn"
                          onChange={handleupdate2}
                          placeholder="add title in English"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Title Hi*</label>
                        <input
                          required
                          value={formdata2.titleHi}
                          name="titleHi"
                          onChange={handleupdate2}
                          type="text"
                          placeholder="add title in Hindi"
                        ></input>
                      </div>

                    

                      <div className="each-input">
                        <label>Content En*</label>
                        <input
                          //                         required
                          value={formdata2.contentEn}
                          name="contentEn"
                          onChange={handleupdate2}
                          placeholder="add content in English"
                          type="text"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Content Hi*</label>
                        <input
                          //                         required
                          value={formdata2.contentHi}
                          name="contentHi"
                          onChange={handleupdate2}
                          placeholder="add content in Hindi"
                          type="text"
                        ></input>
                      </div>

                      <div   className="each-input">
                        <label>Month*</label>
                        <Select
                       id="slect-month"
                          defaultValue={defaultvalue}
                          isDisabled={true}
                          options={selectOption}
                        />
                      </div>
                      <div  className="each-input">
                        <label>Slug*</label>
                        <input
                        style={{background:"#f2f2f2"}}
                          required
                          value={formdata.slug}
                         disabled={true}
                         
                          placeholder="slug"
                          type="text"
                        ></input>
                      </div>
                    


                      <div className="each-input">
                        <label>Festival Sub Category Image url*</label>
                        <input
                          required
                          value={formdata2.festivalSubCategoryImage}
                          name="festivalSubCategoryImage"
                          onChange={handleupdate2}
                          placeholder="add festival fub category image url here"
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="add-f-parent2">
                                                                                                    
                      <button onClick={()=>{setStage2(false)}} className="add-festivel">
                        back
                      </button>
                                                                                                    
                      <button type="" className="add-festivel">
                        submit
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
