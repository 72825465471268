import React, { useState } from "react";
import { useFormik } from "formik";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const url = process.env.REACT_APP_BASE_URL;

const Index = ({ section, sectonvalue, parentid,setSave,toggle }) => {
  // console.log("process.env.REACT_APP_BASE_URL", process.env.REACT_APP_BASE_URL);
  const [display, setDisplay] = useState(false);
  const [copied, setCopied] = useState(false);
  const [message, setMessage] = useState("");
  const history = useNavigate();
  const uploadImage = async (values) => {
    // console.log(values)
    try {
      const formdata = new FormData();
      for (let key in values) {
        formdata.append(key, values[key]);
      }
      axios
        .post(`${`https://082e-2401-4900-1cd7-7818-c513-7543-ac87-404a.ngrok-free.app`}/admin/upload-image-to-aws`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "any",
          },
        })
        .then((result) => {
          if (result.data.status == "success") {
            // console.log(result);s
            setDisplay(true);
            setMessage("Image URL: " + result.data.url);
          }
        })
        .catch((err) => {
          console.log("Error:::this:::::", err);
        });
    } catch (err) {
      console.log("Error::::::::", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      type: parentid ? parentid : "1",
      name: "",
      image: "",
    },
    onSubmit: (values) => {
      uploadImage(values);
    },
    validationSchema: () =>
      Yup.object().shape({
        name: Yup.string()
          .required("Name is required")
          .min(3, "Please enter correct name."),
        type: Yup.string().required("Type is required"),
        image: Yup.string().required("Image is required"),
      }),
  });

  const inputError = (formik, input) => {
    return (
      formik.errors[input] &&
      formik.touched[input] && (
        <div
          style={{
            color: "rgb(235, 54,54)",
            fontSize: "14px",
          }}
        >
          {formik.errors[input]}
        </div>
      )
    );
  };

  const copytoclip = (text) => {
    navigator.clipboard.writeText(text).then(setCopied(true));
    if (section) {
      
    
      setSave(text)
      toggle()
    } else {
      history("/dashboard")
    }
  };
  return (
    <section className="postCountIV">
      <Form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Label for="name" className="lableCategory">
            Name:
          </Label>
          <Input
            type="name"
            name="name"
            id="name"
            placeholder={`Enter Image Name`}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {inputError(formik, "name")}
        </FormGroup>
        <FormGroup>
          <Label for="image" className="lableCategory">
            Image:
          </Label>
          <Input
            type="file"
            name="image"
            id="image"
            placeholder={` Image`}
            onChange={(event) => {
              formik.setFieldValue("image", event.currentTarget.files[0]);
            }}
          />
          {inputError(formik, "image")}
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect" className="lableCategory">
            Type:
          </Label>
          {!section && (
            <Input
              type="select"
              name="type"
              id="exampleSelect"
              // value={formik.values.}
              onChange={formik.handleChange}
            >
              <option value="1">Festival</option>
              <option value="2">English Blog</option>
              <option value="3">Hindi Blog</option>
              <option value="4">Wellness</option>
              <option value="5">VratKatha</option>
              <option value="6">Rituals</option>
              <option value="7">Temple</option>
              {/* <option value="8">Hotels</option> */}
            </Input>
          )}
          {section && (
            <Input
              type="select"
              name="type"
              id="exampleSelect"
         
              disabled={true}
              // value={formik.values.}
            >
              <option value={parentid}>{sectonvalue}</option>
            </Input>
          )}
          {inputError(formik, "type")}
        </FormGroup>
        <Button
          style={{ display: "inline-block", width: "200px", float: "right" }}
          type="submit"
        >
          <strong>Upload image to AWS</strong>
        </Button>
      </Form>

      {display && (
        <div>
         <p> {message}</p>
          <div>
            <button
              type="button"
              onClick={() => {
                copytoclip(message.split(" ")[2]);
              }}
              className="add-festivel"
            >
              {copied ? "Copied" : "Copy"}
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Index;
