import React, { useState, useEffect } from "react";
import Sidebar from "../Side";
import Navigation from "../Navigation";
import { useLocation } from "react-router-dom";
import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;
const Index = (props) => {
  const history = useLocation();
  const [newUserList, setnewUserList] = useState(history.state);
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "New Account";
  const active = "active";

  const NewUserList = () => {
    axios
      .get(`${url}/admin/new-users-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setnewUserList(res.data.data);
      })
      .catch((err) => {
        console.log("Error::::::::", err);
      });
  };
  // useEffect(() => {
  //     NewUserList()
  // }, [])
  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} ugc={active} />{" "}
            <div className="grid-child">
              {" "}
              <Navigation data={pageTitle} />
              <section className="postCount" style={{ overflow: "scroll" }}>
                <table
                  class="table table-striped table-bordered"
                  style={{
                    borderRadius: "3px",
                    padding: "5px",
                    backgroundColor: "white",
                  }}
                >
                  <thead>
                    <tr colspan="12">
                      <th colspan="2">S.NO</th>
                      <th colspan="2">User ID</th>
                      <th colspan="2">User Name</th>
                      <th colspan="2">Mobile Number</th>
                      <th colspan="2">Email</th>
                      <th colspan="2">Login Type</th>
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {newUserList &&
                      newUserList.length > 0 &&
                      newUserList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td colspan="2">{index + 1}</td>
                            <td colspan="2">{item.id}</td>
                            <td colspan="2">{item.name}</td>
                            <td colspan="2">
                              {item.mobile_number ? item.mobile_number : "--"}
                            </td>
                            <td colspan="2">
                              {item.email ? item.email : "--"}
                            </td>
                            <td colspan="2">{item.login_type}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </section>
            </div>{" "}
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
