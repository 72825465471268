import React from 'react'
import Sidebar from '../../Side'
import Navigation from '../../Navigation'
import ReportHashTagsList from './ReportHashTagsList'
const index = () => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("user");
    const pageTitle = 'Report HashTags';
    const active = 'active';
    if(token){
    return (
        <>
             <div className="main-parentcontainer">
                <div className="grid-parent">
                <Sidebar data={name} ugc={active} />
                        <div className='grid-child'> <Navigation data={pageTitle} />
                        <ReportHashTagsList /></div>
                    </div></div>
           
           
        </>
    )}
    else{
        window.location.href = '/'
    }
}

export default index
