import React from 'react';
import Tabs from '../../Tabs/Tabs';
// import Astrology from '../Tabs/Month'

const Blogs = () => {

    let Aartis  = [
       
        { name:"Mythology" }, {name:"Hindu Festival"} , {name:"Beliefs"} ,{ name:"Spirituality"}, {name:"Astrology"} ,{ name:"Rituals"} ,{ name:"Temple"} , {name:"Hindu Marriage"} , {name:"Miscellaneous"}  ]
    
    
    
      return (
    
        <section className='postCountIV'>
          <div>
        <Tabs>
          {Aartis && Aartis.length>0 && Aartis.map((items, index) => {
            return <div key={index} label={items.name} >
              {/* <Astrology data={items.id}/> */}
            </div> 
    
          })}
        </Tabs>
        </div>
      </section>
    


    )
}
export default Blogs




 // return (
    //     <section className='postCountIV'>
    //         <Tabs>
    //             <div label="Mythology">
    //                 <Astrology />
    //             </div>
    //             <div label="Hindu Festival">
    //                 <Astrology />
    //             </div>
    //             <div label="Beliefs">
    //                 <Astrology />
    //             </div>
    //             <div label="Spirituality">
    //                 <Astrology />
    //             </div>
    //             <div label="Astrology">
    //                 <Astrology />
    //             </div>
    //             <div label="Rituals">
    //                 <Astrology />
    //             </div>
    //             <div label="Temple">
    //                 <Astrology />
    //             </div>
    //             <div label="Hindu Marriage">
    //                 <Astrology />
    //             </div>
    //             <div label="Miscellaneous">
    //                 <Astrology />
    //             </div>
    //         </Tabs>
    //     </section>