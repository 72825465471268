import React from 'react'
import Sidebar from '../../Side'
import Navigation from '../../Navigation'
import ReportShortsLists from './ReportShortsList'
const index = () => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("user")
    const pageTitle = 'Report Shorts';
    const active = 'active';
    if (token && token != 'undefined' ) {
        return (
            <>
                 <div className="main-parentcontainer">
                    <div className="grid-parent">
                    <Sidebar data={name} ugc={active} />
                        <div className='grid-child'> <Navigation data={pageTitle} />
                        <ReportShortsLists /></div>
                    </div></div>
               
               
            </>
        )
    }
    else {
        window.location.href = '/'
    }
}

export default index
