import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const url = process.env.REACT_APP_BASE_URL;
const img = require('../../../../../assets/img/dddd.webp')

const Index = (props) => {
  const history = useNavigate();
  const [blogCategory, setBlogCategory] = useState([]);
  const [englishBlog, setEnglishBlog] = useState([]);
  const [skip, setSkip] = useState(0);
  const [id, setId] = useState(72);
  const URL = process.env.REACT_APP_BASE_URL;

  const getCategory = async (data,language,basic) => {
    await axios
      .get(
        `${URL}/pg/blog/hi-post-details?slug=${data.slug}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if(basic){
          history("/basic-details", {
            state: {
              data : result.data.blogPost,
              language: language,
              pageTitle:"Hindi Blog",
              type:5
            },
          });
        }
        else{   
          history("/text-editor", {
          state: {
            data: data,
            language: language,
            content : result.data.blogPost.content,
            type : 5
            
          },
        });
      }
      });
  };

  const BlogCategoryData = async () => {
    await axios
      .get(`${url}/pg/blog/list-of-hi-blog-category`, {
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setBlogCategory(res.data.data);
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  const BlogsData = async (params1) => {
    await axios
      .get(`${url}/pg/blog/list-of-hi-blog`, {
        params: {
          categorySlug: params1,
          skip: skip,
          limit: 200,
        },
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setEnglishBlog(res.data.blogList);
      })
      .catch((err) => console.log("Error::::::::", err));
  };
  const changeStyle = (id) => {
    setId(id);
  }

  useEffect(() => {
    BlogCategoryData();
    BlogsData('adhyatmikata')
  }, []);

  return (
    
      <div className="postCount" style={{marginLeft:"60px",marginTop:"50px",cursor:"pointer"}}>
        {blogCategory && blogCategory.length > 0 && blogCategory.map((element, index) => {
          return (
            <div 
              key={index} 
                className={[element.id==id?"BlogNavBar1":"BlogNavBar"]}
              onClick={() => { 
                changeStyle(element.id)
                BlogsData(element.slug)
              }}
            >
               <strong> {element.category_name}</strong>
            </div>
          );
        })}
        <div className="postCountIII">
          <button
        style={{
          // zIndex: "2",
          // right: "150px",
          margin: "5px",
          marginLeft: "10px",
          top: "0px",
          // position: "absolute",
          width: "150px",
          height: "34px",
          background: "orange",
          border: "none",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "14px",
          fontWeight: "bold",
          color: "black",
        }}
        onClick={()=>{
          history("/basic-details", {
          state: {
            data : blogCategory,
            language: "language",
            pageTitle:"Create Hindi Blog",
            type:7
          },
        })}}
        
      >
        Add New Blog
      </button>
       
        <div className="grid-container">
          {englishBlog &&
            englishBlog.length > 0 &&
            englishBlog.map((element, index) => {
              return <div key={index} style={{backgroundImage:element?`url(${element.image})`:"",backgroundSize: "cover",
              backgroundPosition: "center center",boxShadow:"inset 0px 0px 0px 200px rgba(0,0,0,0.25)"}}>
                <div style={{position:"relative"}}>
            <span className='imageAdd'><FontAwesomeIcon icon={faImage} size='sm' style={{color:"white"}} className='imageAddIcon' /></span>
                <p className="spanP"><strong>{element.title}</strong></p>
                <div  >
                      {/* <button className='boxbutton' onClick={() => { getCategory(element, "en"); }} > English </button> */}
                      <button className='boxbutton' onClick={() => { getCategory(element, "en"); }} > Content </button>
                      <button className='boxbutton' onClick={() => { getCategory(element, "en","basic");}}>basic</button>
                      </div> 
              </div>
              </div>
            })}
        </div>
      </div>
      </div>
  );
};

export default Index;
