import React, { useState } from 'react'
import {
    Input,
    Button, Form
} from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from "yup";
const URL = process.env.REACT_APP_BASE_URL;
const Index = () => {
    const token = localStorage.getItem("token");
    const [isLoggedIn, setLoggedIn] = useState(false);
    const history = useNavigate();
    if (token && token != 'undefined') {
        window.location.href = '/dashboard'
    }

    const postLogin = async (formData) => {
        try {
            let response = await fetch(`${URL}/admin/login`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "post",
                body: JSON.stringify(formData),
            });
            const result = await response.json();
            if (result.status == 'success') {
                localStorage.setItem("token", result.token);
                localStorage.setItem("user", "Rgyan Admin");
                formik.setSubmitting(false);
                setLoggedIn(true);
                history('/dashboard')

            } else {
                formik.setSubmitting(false);
            }
        } catch (err) {
            console.log("Error::::::::", err)
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            postLogin(values);
        },
        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string()
                    .required("Required")
                    .email("Please enter a valid email."),
                password: Yup.string()
                    .required("required")
                    .min(8, "Password should be 8 or more than 8 character."),
            }),
    })

    return (
        <div className="demo-wrap">
            <div className='emptyContainer'></div>
            <div className="demo-content">
                <p className='text-center logo'>Rgyan</p>
                <p className='text-center logo-text'>Spiritual Social Network</p>
                <Form className='formContainerrr' onSubmit={formik.handleSubmit}>
                    <Input type="email" name="email" id="emailField" className='inputField' placeholder="Login Id" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.errors.email &&
                        formik.touched.email && (
                            <div className="error-n">
                                {formik.errors.email}</div>
                        )}
                    <Input type="password" name="password" className='inputField' id="passwordField" placeholder="Password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.errors.password &&
                        formik.touched.password && (
                            <div className="error-n">
                                {formik.errors.password}
                            </div>
                        )}
                    <Button className='button' type='submit'>Login</Button>

                </Form>
            </div>
            {/* <div className="demo-content">
                                <p>
                                    <Link to=" ">
                                        Forgot Password
                                    </Link>
                                </p>
                                <p>
                                    Don't have an account ?
                                    <Link
                                        to=""
                                    >
                                        Sign Up
                                    </Link>
                                </p>
                            </div> */}
        </div>
    )
}

export default Index




