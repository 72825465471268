import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const url = process.env.REACT_APP_BASE_URL;

const Index = (props) => {
  const history = useNavigate();
  const [blogCategory, setBlogCategory] = useState([]);
  const [englishBlog, setEnglishBlog] = useState([]);
  const [skip, setSkip] = useState(0);
  const [id, setId] = useState(2736);
  const [enable, setEnable] = useState(true)
  const [current, setCurrent] = useState(1)
  const URL = process.env.REACT_APP_BASE_URL;


  const getCategory = async (data, language, basic) => {
    // console.log(data)
    await axios
      .get(
        `${URL}/pg/blog/en-post-details?slug=${data.slug}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "any"
          },
        }
      )
      .then((result) => {
        // console.log(result)
        if (basic) {
          history("/basic-details", {
            state: {
              data: data,
              language: language,
              pageTitle: "English Blog",
              type: 4
            },
          });
        }
        else {
          history("/text-editor", {
            state: {
              data: data,
              language: language,
              content: result.data.blogPost.content,
              type: 4

            },
          });
        }
      });
  };


  const BlogCategoryData = async () => {
    await axios
      .get(`${url}/pg/blog/list-of-en-blog-category`, {
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
          "Content-Type": "application/json",
           "ngrok-skip-browser-warning": "any"
        },
      })
      .then((res) => {
        setBlogCategory(res.data.data);
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  const BlogsData = async (params1) => {
    await axios
      .get(`${url}/pg/blog/list-of-en-blog`, {
        params: {
          categorySlug: params1,
          skip: skip,
          limit: 200,
        },
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any"
        },
      })
      .then((res) => {
        const data = res.data.blogList.filter((each) => { return each != null });

        // const newdata = enable ? data.filter((each) => { return each.postCategoryStatus == 0 }) : data.filter((each) => { return each.postCategoryStatus == 1 })
        setEnglishBlog(data);
      })
      .catch((err) => console.log("Error::::::::", err));
  };
  const changeStyle = (id) => {
    setId(id);
  }

  useEffect(() => {
    BlogCategoryData();
    BlogsData('calendar')
  }, []);

  console.log("englishBlog", englishBlog)

  return (

    <div className="postCount" style={{ cursor: "pointer", display: "grid", gridTemplateRows: "1fr 7fr" }} >
      <div className="blog-correct"  >

        <div style={{ display: "flex" }}  >
          {blogCategory && blogCategory.length > 0 && blogCategory.map((element, index) => {

            return (
              <div
                key={index}
                className={[element.id == id ? "BlogNavBar1" : "BlogNavBar"]}
                onClick={() => {
                  changeStyle(element.id)
                  BlogsData(element.slug)
                }}
              >
                <strong> {element.category_name}</strong>
              </div>
            );
          })}  </div>

        <div className="btn-add"><button
          style={{
            // zIndex: "2",
            // right: "150px",
            margin: "5px",
            marginLeft: "10px",
            top: "0px",
            // position: "absolute",
            width: "150px",
            height: "34px",
            background: "orange",
            border: "none",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "14px",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={() => {
            history("/add-new-english-blog", {
              state: {
                data: blogCategory,
                language: "language",
                pageTitle: "Create English Blog",
                type: 8
              },
            })
          }}

        >
          Add New
        </button></div>

      </div>

      <div className="postCountIII">
        <div className="status">
          {[{ no: 1, name: "Active Blogs" }, { no: 2, name: "Disabled Blogs" }].map((e) => {
            return <button onClick={() => {
              setEnable(!enable)
              setCurrent(e.no)
            }} key={e.name} className={current === e.no && "enable"}>{e.name}</button>

          })}

        </div>
        <div className="grid-container">
          {englishBlog.length &&
            englishBlog.filter((each) => {
              return enable ? each.postStatus == 0 : each.postStatus == 1
            }).map((element, index) => {
              // console.log(element.postCategoryStatus, "ele", enable)
              return (<div key={index} style={{
                backgroundImage: `url(${element.image})`, backgroundSize: "cover",
                backgroundPosition: "center center", boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                // visibility: `${element.postCategoryStatus === enable ? "hidden" : "visible"}`
              }}>
                <div style={{ position: "relative" }} className="detail-outer-box">

                  <span className='imageAdd'><FontAwesomeIcon icon={faImage} size='sm' style={{ color: "white" }} className='imageAddIcon' /></span>
                  <div>
                    <p className="spanP"><strong>{element.title}</strong></p>
                    <div >
                      <button className='boxbutton' onClick={() => { getCategory(element, "en"); }} > Content </button>
                      <button className='boxbutton' onClick={() => { getCategory(element, "en", "basic"); }}>basic</button>
                    </div></div>
                </div>
              </div>)
            })}


        </div>

      </div>
    </div>
  );
};

export default Index;


