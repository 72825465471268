import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Side";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const url = process.env.REACT_APP_BASE_URL;

const Seo = (props) => {
  const [festival, setFestival] = useState();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Festival SEO";
  const active = "active";
  if (token && token != "undefined") {
    const HandleRedirect = (value) => {
      navigate("/edit-seo", {
        state: value,
      });
    };

    const Data = async () => {
      await axios
        .get(url + "/admin/festival-seo-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            client: 3,
          },
        })
        .then((res) => {
          setFestival(res.data.data);
        })
        .catch((err) => {
          console.log("Error::::::::", err);
        });
    };
    useEffect(() => {
      Data();
    }, []);
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} ugc={active} />
            <div className="grid-child">
              {" "}
              <Navigation data={pageTitle} />
              <section
                className="postCount"
                style={{ overflow: "scroll", height: "95vh" }}
              >
                <Link to="/add-seo-festival">
                  <button
                    className="addCategoryButton"
                    style={{ float: "right" }}
                  >
                    Add New SEO{" "}
                  </button>
                </Link>
                <table
                  class="table table-striped table-bordered"
                  style={{
                    borderRadius: "3px",
                    padding: "5px",

                    backgroundColor: "white",
                  }}
                >
                  <thead>
                    <tr colspan="12">
                      <th colspan="2">S.NO</th>
                      <th colspan="2">Catagory ID</th>
                      <th colspan="2">Title</th>
                      <th colspan="2">meta Description</th>
                      {/* <th colspan="2">
                                meta Keyword
                                </th> */}
                      {/* <th style={{width:"10px"}} colspan="2">
                                meta_robots
                                </th> */}
                      {/* <th colspan="2">
                               meta og image
                                </th> */}
                      <th colspan="2">Title hindi</th>
                      <th colspan="2">Meta Description Hindi</th>
                      {/* <th colspan="2">
                                    Meta Keyword Hindi
                                </th> */}
                      {/* <th colspan="2">
                                meta og url hi
                                </th> */}

                      <th colspan="2">Edit</th>
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {festival &&
                      festival.length > 0 &&
                      festival.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td colspan="2">{index + 1}</td>
                            <td colspan="2">{item.category_id}</td>
                            <td colspan="2">{item.title}</td>
                            <td colspan="2">{item.meta_description}</td>

                            <td colspan="2">{item.title_hi}</td>
                            <td colspan="2">{item.meta_description_hi}</td>

                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                HandleRedirect(item);
                              }}
                            >
                              {" "}
                              Edit{" "}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </section>
            </div>{" "}
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Seo;
