import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import qs from "qs";
// import data from '../Data'
import { Table } from "reactstrap";
import axios from "axios";
const img = require("../../assets/img/user.png");
const url = process.env.REACT_APP_BASE_URL;

function App({ data }) {
  const [comments, setComments] = useState([]);
  const [hasPosts, setHasPosts] = useState(true);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  const NewUserList = async () => {
    await axios
      .get(`${url}/admin/new-users-list`, {
        params: {
          skip: skip,
          limit: 10,
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setComments([...comments, ...res.data.data]);
        setLimit(limit + 10);
        setSkip(skip + 10);
        if (res.data.data.length < 10) {
          setHasPosts(false);
        }
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  // useEffect(() => {
  //   NewUserList()
  // }, []);
  function handleFetch(params) {
    const strParams = qs.stringify(params);

    const token = `Bearer ${localStorage.getItem("token")}`;
    let url = process.env.REACT_APP_BASE_URL;
    if (strParams) {
      url = url + "/admin/new-users-list?" + strParams;
    }

    axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setComments(...comments, ...res.data.data);
      })
      .catch((err) => console.log("Error::::::::", err));
  }

  return (
    <div className="Appp">
      {data && data.length > 0 && (
        // <InfiniteScroll
        //   pageStart={0}
        //   loadMore={() => {
        //     // NewUserList();
        //   }}
        //   hasMore={hasPosts}
        //   loader={<div key={0}>Loading</div>}
        //   useWindow={false}
        // >
          <Table hover striped className="textAlign">
            <thead className="assssss">
              <tr>
                <th>Profile Photo/Name</th>
                <th>Age & Gender</th>
                <th>Mobile</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data &&
                data.length > 0 &&
                data.map((x, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={
                          x.avatar === "user.png" || x.avatar === ""
                            ? img
                            : x.avatar
                        }
                        alt="profilePic"
                        className="profilePic"
                      />
                      <p>{x.name}</p>
                    </td>
                    <td className="aaaaaa">
                      {x.gender === "null" || x.gender === null
                        ? "Others"
                        : x.gender}
                    </td>
                    <td className="aaaaaa">
                      {x.mobile_number ? x.mobile_number : "--"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        // </InfiniteScroll>
      )}
    </div>
  );
}

export default App;
