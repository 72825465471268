import React, { useEffect, useState } from 'react'
import Navigation from "../../Navigation";
import axios from "axios";
import Sidebar from "../../Side";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";

const Index = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const pageTitle = "Trending Blog ";
  const active = "active";
  const name = "Sprituality";
  const [trendingData, setTrendingData] = useState([]);
  const [eid, setId] = useState("1");
  const [language, setLanguage] = useState("en");
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const onDismiss = () => setVisible(false);
  const [visible, setVisible] = useState(true);
  const [success, setSuccess] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const URL = process.env.REACT_APP_BASE_URL;
  const changeStyle = (id) => {
    setId(id);
  }

  const TrendingPost = async (language) => {
    await axios
      .get(
        `${URL}/admin/get-trending-blogs?language=${language}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setTrendingData(result.data.data)
        setLanguage(language)
      });
  }
  useEffect(() => {
    TrendingPost("en")
  }, [])

  const EditEnglishBlogDetails = (values, id) => {
    fetch(
      `${URL}/admin/update-en-blog-post?blogId=${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            window.location.reload()
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditHindiBlogDetails = (values, id) => {
    fetch(
      `${URL}/admin/update-hi-blog-post?blogId=${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            window.location.reload()
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  if (token && token != 'undefined' ) {

    return (

      <div>
         <div className="main-parentcontainer">
          <div className="grid-parent">
          <Sidebar data={name} pgc={active} />
                        <div className='grid-child'>  <Navigation data={pageTitle} />
        <div className="postCount" style={{ marginLeft: "60px", marginTop: "50px", cursor: "pointer" }}>
          <p className={["1" == eid ? "BlogNavBar1" : "BlogNavBar"]} onClick={() => {
            changeStyle("1")
            TrendingPost("en")

          }} >English</p>
          <p className={["2" == eid ? "BlogNavBar1" : "BlogNavBar"]} onClick={() => {
            changeStyle("2")
            TrendingPost("hi")
          }}>Hindi</p>
          <div className="postCountIII" >


            <div className="grid-container">
              {trendingData &&
                trendingData.length > 0 &&
                trendingData.map((element, index) => {
                  return <div key={index} style={{
                    backgroundImage: `url(${element.image})`, backgroundSize: "cover",
                    backgroundPosition: "center center", boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.25)"
                  }}>
                    <div style={{ position: "relative" }}>
                      <span className='imageAdd'><FontAwesomeIcon icon={faImage} size='sm' style={{ color: "black" }} className='imageAddIcon' /></span>
                      <p className="spanP"><strong>{element.title}</strong></p>

                      <div>
                        <button className='boxbutton' onClick={() => {
                          if (language == "en") {
                            EditEnglishBlogDetails({ isTrending: false }, element.id)
                          } else {
                            EditHindiBlogDetails({ isTrending: false }, element.id)
                          }
                        }}>Disable</button>
                      </div>
                    </div>
                  </div>
                    ;
                })}

            </div>
          </div>

        </div></div>
          </div></div>
        

       
      
      </div>
    )
  }
  else {
    window.location.href = '/'
  }
}

export default Index
