const deviceStorage = {
    async logOut() {
        try {
            localStorage.removeItem("token");
            localStorage.removeItem("user")
            window.location.href = '/';
        } catch (err) {
            console.log("Error::::::::", err);
        }
    }
}
export default deviceStorage;