import React, { useRef } from 'react'


function Index() {
  const videoRef = useRef();
  const token = localStorage.getItem("token");


  const handleplay = () => {
    videoRef.current.play()
  }
  const handlepause = () => {
    videoRef.current.pause()
  }
  const data = [
    { id: 1, src: 'https://rgyan-flutter200503-dev.s3.ap-south-1.amazonaws.com/public/12184/videos/video_1665466123040819.mp4' },
    { id: 2, src: 'https://rgyan-flutter200503-dev.s3.ap-south-1.amazonaws.com/public/12184/videos/video_1665466123040819.mp4' },
    { id: 3, src: 'https://rgyan-flutter200503-dev.s3.ap-south-1.amazonaws.com/public/12184/videos/video_1665466123040819.mp4' },
    { id: 4, src: 'https://rgyan-flutter200503-dev.s3.ap-south-1.amazonaws.com/public/12184/videos/video_1665466123040819.mp4' }
  ]
  if (token && token != 'undefined' ) {

    return (
      <>
        <div style={{ height: "50vh", width: "50vw" }} >


          <button style={{ width: "100px", height: "50px", background: "green" }} onClick={handleplay} > play </button>
          <button style={{ width: "100px", height: "50px", background: "red" }} onClick={handlepause} > pause </button>

        </div>

        {data.map((item, i) => {
          return <video height="300" width="300" ref={videoRef}>
            <source src={item.src} />
          </video>
        })}



      </>
    )
  }
  else {
    window.location.href = '/'
  }
}

export default Index