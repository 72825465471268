import React, { useState } from 'react';
import { Field, useFormik } from 'formik';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import {
  FormGroup, Label, Input,
  Button, Form, Row, Col, Alert
} from 'reactstrap';
import * as Yup from 'yup';
import Navigation from '../../../Navigation';
import Sidebar from '../../../Side';


const url = process.env.REACT_APP_BASE_URL

function Index() {
  const history = useLocation()
  const router = useNavigate()
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const onDismiss = () => setVisible(false);
  const [data, setData] = useState(history.state)


  const addCatagory = (values) => {
    axios.post(`${url}/admin/add-new-video-category`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(
        (result) => {
          if (result.status == '200') {
            setIsSuccess(true);
            setMessage('Successfully Add Catagory');

            setTimeout(() => {
              setIsSuccess(false);
              setMessage(''); 
            }, 4000)
          }
          formik.resetForm();
        },
        (err) => {
          console.log("Error::::::::", err);
          setIsError(true);
          setError('Something went wrong. Try again');
        }
      );
  };
  const formik = useFormik({
    initialValues: {
      channelId: '',
      channelName: '',
      isDisable: '',
      isSpecial: '',
      videoId: '',
      videoName: '',
      categoryName: '',
      videoShortName: '',
      thumbnail: ''

    },
    onSubmit: (values) => {

      addCatagory(values)
    },
    validationSchema: () =>
      Yup.object().shape({
        categoryName: Yup.string()
          .required("Category name is required"),
        videoId: Yup.string().required("videoId  is required"),
      }),
});



  const token = localStorage.getItem('token');
  const name = localStorage.getItem("user")
  const pageTitle = 'Live Darshan';
  const active = 'active';

  if (token && token != 'undefined' ) {

    return (
      <>


        <section className='postCount' style={{ overflow: 'scroll' }}>

          <Form onSubmit={formik.handleSubmit} style={{ padding: '10px 20px 0px 20px' }}>
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">


                <Row form>
                  <Col>
                    <Label for="name" className='lableCategory'>Channel ID:</Label>
                    <Input type="text"
                      name="channelId"
                      values={formik.values.channelId}
                      placeholder={`Enter Channel ID`}
                      onChange={formik.handleChange} />
                  </Col>
                  <Col>
                    <Label for="name" className='lableCategory'>Channel Name:</Label>
                    <Input type="text"
                      name="channelName"
                      placeholder={`Enter Channel Name`}
                      values={formik.values.channelName}
                      onChange={formik.handleChange} />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className='lableCategory'>Video Id:</Label>
                    <Input type="text"
                      name="videoId"
                      required
                      placeholder={`Enter Video ID`}
                      values={formik.values.videoId}
                      onChange={formik.handleChange} />
                      
                  </Col>
                  <Col>
                    <Label for="name" className='lableCategory'>Video Name:</Label>
                    <Input type="text"
                      name="videoName"

                      placeholder={`Enter Video Name`}
                      values={formik.values.videoName}

                      onChange={formik.handleChange} />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className='lableCategory'>Category Name:</Label>
                    <Input type="text"
                      name="categoryName"
                      required
                      placeholder={`Enter Category Name`}
                      value={formik.values.categoryName}
                      onChange={formik.handleChange} />
                  </Col>
                  <Col>
                    <Label for="name" className='lableCategory'>Video Short Name:</Label>
                    <Input type="name"
                      name="videoShortName"
                      placeholder={`Video Short Name`}
                      value={formik.values.videoShortName}
                      onChange={formik.handleChange} />
                  </Col>

                </Row>
                <Row form>
                  <Col>
                    <Label for="radio" className='lableCategory'>Disable:</Label><br />
                    <Input type="radio"
                      name="isDisable"
                      id="radio"
                      value={true}
                      placeholder={`Change Category Image`}
                      onChange={formik.handleChange} />{" True "}
                    <Input type="radio"
                      name="isDisable"
                      id="radio"
                      value={false}
                      placeholder={`Change Category Image`}
                      onChange={formik.handleChange} />{" False "}
                  </Col>
                  <Col>
                    <Label for="radio" className='lableCategory'>Special:</Label><br />
                    <Input type="radio"
                      name="isSpecial"
                      id="radio"
                      value={true}
                      placeholder={`Change Category Image`}
                      onChange={formik.handleChange} />{" True "}
                    <Input type="radio"
                      name="isSpecial"
                      id="radio"
                      value={false}
                      placeholder={`Change Category Image`}
                      onChange={formik.handleChange} />{" False "}
                  </Col>
                </Row>
                <Row from>
                  <Col>
                    <Label for="name" className='lableCategory'>Thumbnail:</Label>
                    <Input type="name"
                      name="thumbnail"

                      placeholder={`Enter Thumbnail`}
                      value={formik.values.thumbnail}

                      onChange={formik.handleChange} />
                  </Col>
                </Row>
              </div>
            </div>
            <Button style={{ display: 'inline-block', width: "200px", float: "right", marginTop: "15px", background: 'linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)' }} type="submit"><strong>Add Live Category</strong></Button>
          </Form>
        </section>
      </>
    );
  }
}

export default Index;

 