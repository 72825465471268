import React, { useState } from "react";
import Sidebar from "../../Side";
import { createElement } from "react";
import Navigation from "../../Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SubUploadImage from "../../../Others/UploadImage/SubUploadImage";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ButtonToggle,
} from "reactstrap";
import Select from "react-select";
const index = () => {
  // basic page info
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Festivals ";
  const active = "active";

  // for data pass and get
  const history = useLocation();
  // console.log(history);
  const navigate = useNavigate();
  const [selmonth, setselmonth] = useState(history.state);
  const url = process.env.REACT_APP_BASE_URL;
  // state management and true false
  const [visible, setVisible] = useState(true);
  const [formdata, setformdata] = useState({
    nameEn: history.state.name_en,
    nameHi: history.state.name_hi,
    slug: history.state.slug,
    month: "",
    descriptionEn: history.state.description_en,
    descriptionHi: history.state.description_hi,
    imageUrl: history.state.image,
    festivalDate: ` 2024-${
      history.state.month.toString().length === 1
        ? `0${history.state.month}`
        : history.state.month
    }-${
      history.state.festival_date.toString().length === 1
        ? `0${history.state.festival_date}`
        : history.state.festival_date
    }`,
  });
  // console.log(
  //   `${
  //     history.state.festival_date.toString().length === 1
  //       ? `0${history.state.festival_date}`
  //       : history.state.festival_date
  //   }-${
  //     history.state.month.toString().length === 1
  //       ? `0${history.state.month}`
  //       : history.state.month
  //   }-2024`
  // );
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
 const [save,setSave]=useState("")
  // functions
  const onDismiss = () => setVisible(false);
  const handleupdate = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, ";l;");
    setformdata((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var date = new Date(formdata.festivalDate);
    var day = date.getDate();
    var month = date.getMonth();
    // console.log(date, day, month, ";l;l;");
    const data = {
      nameHi: formdata.nameHi,
      nameEn: formdata.nameEn,
      slug: formdata.slug,
      descriptionEn: formdata.descriptionEn,
      descriptionHi: formdata.descriptionHi,
      imageUrl: save?save:formdata.imageUrl,
      month: month + 1,
      festivalDate: day,
    };
    // console.log(data);
    axios
      .post(`${url}/admin/festival-category-update`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        // console.log(res, "this is res");
        if (res.data.code === 200) {
          setIsSuccess(true);
          setMessage("Festival category added Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            history("/festivals");
          }, 3000);
        }
      })
      .catch((Err) => {
        // console.log("Error::::::::", Err);
        setIsError(true);
        setError(Err.response.data.msg);
      });
  };

  // required data
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const defaultvalue = {
    value: history.state,
    label: months[history.state - 1],
  };
  const selectOption = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const URL = process.env.REACT_APP_BASE_URL;
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  if (token && selmonth) {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <div>
                <div className="alert-side">
                  {isSuccess && (
                    <Alert color="info" isOpen={visible} toggle={onDismiss}>
                      {message}
                    </Alert>
                  )}
                </div>
                <div className="alert-side">
                  {" "}
                  {isError && (
                    <Alert
                      style={{
                        background: "rgb(251, 99, 71)",
                        color: "white",
                      }}
                      isOpen={visible}
                      toggle={onDismiss}
                    >
                      {error}
                    </Alert>
                  )}
                </div>

                <p className="name-add">
                  Update {history.state.name_en} Festival
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form">
                    <div className="each-input">
                      <label>Name En*</label>
                      <input
                        required
                        type="text"
                        value={formdata.nameEn}
                        name="nameEn"
                        onChange={handleupdate}
                        placeholder="add festival name in English"
                      ></input>
                    </div>

                    <div className="each-input">
                      <label>Name Hi*</label>
                      <input
                        required
                        value={formdata.nameHi}
                        name="nameHi"
                        onChange={handleupdate}
                        type="text"
                        placeholder="add festival name in Hindi"
                      ></input>
                    </div>

                    <div className="each-input">
                      <label>Slug*</label>
                      <input
                        required
                        id="slect-month"
                        disabled={true}
                        style={{ background: "#f2f2f2" }}
                        value={formdata.slug}
                        name="slug"
                        onChange={handleupdate}
                        type="text"
                        placeholder="Cretae a unique slug"
                      ></input>
                    </div>

                    <div className="each-input">
                      <label>Description En*</label>
                      <input
                        id="slect-month"
                        required
                        style={{ background: "#f2f2f2" }}
                        disabled={true}
                        value={formdata.descriptionEn}
                        name="descriptionEn"
                        onChange={handleupdate}
                        placeholder="add description in English"
                        type="text"
                      ></input>
                    </div>

                    <div className="each-input">
                      <label>Description Hi*</label>
                      <input
                        required
                        style={{ background: "#f2f2f2" }}
                        disabled={true}
                        id="slect-month"
                        value={formdata.descriptionHi}
                        name="descriptionHi"
                        onChange={handleupdate}
                        placeholder="add description in Hindi"
                        type="text"
                      ></input>
                    </div>

                    {/* <div className="each-input">
                      <label>Month*</label>
                      <Select
                        defaultValue={defaultvalue}
                        onChange={(e) => {
                          setselmonth(e.value);
                        }}
                        options={selectOption}
                      />
                    </div> */}

                    <div className="each-input">
                      <label>Festival Date* </label>
                      <input
                   
                        required
                        value={moment(formdata.festivalDate).format(
                          "YYYY-MM-DD"
                        )}
                        // placeholder={"2024-01-09"}
                        name="festivalDate"
                        onChange={handleupdate}
                        type="date"
                      ></input>
                    </div>

                    <div className="each-input">
                      <label>Image url*</label>
                      <input
                             id={save && "slect-month"}
                        required
                        disabled={save?true:false}
                        value={save?save:formdata.imageUrl}
                        name="imageUrl"
                        onChange={handleupdate}
                        placeholder="add your image url here"
                        type="text"
                      ></input>
                    </div>
                  </div>
                  <div className="add-f-parent2">
                    <button type="submit" className="add-festivel">
                      Update Now
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setModal(true);
                      }}
                      className="add-festivel"
                    >
                      {save ? "Change Image" : "Upload Image"}
                    </button>
                  </div>
                </form>
              </div>
            </div>{" "}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle} id="upload-image-width">
          <ModalHeader toggle={toggle}>Upload Image On Aws</ModalHeader>
          <ModalBody><div className="upload-div">

         
            <SubUploadImage setSave={setSave} toggle={toggle}  parentid={"1"} section={true} sectonvalue={"festival"} />  </div>
          </ModalBody>
         
        </Modal>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
