import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import Data from '../../../../Data';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
const url = process.env.REACT_APP_BASE_URL


export default function FullWidthGrid(props) {
  const [festivals, setFestivals] = useState([])
  const history = useNavigate();

  const FestivalMonthId = props.data
  function subCat(data) {
    history('/vrathKathaEditor', { state: data })
  }
  const token = `Bearer ${localStorage.getItem("token")}`

  const getContent = async (data,language,basic) => {
    await axios.get(
      url+`/pg/devotion/varat-khathas-post/${data.id}?language=${language}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((result) => {
      if(basic){
        history("/basic-details", {
          state: {
            data: data,
            language: language,
            pageTitle:"Vrat Katha",
            type:2
          },
        });
      }else{
        history("/text-editor", {
          state: {
            data: data,
            language: language,
            content : result.data.data,
            type : 2
          },
        });
      }
      
    });
  }

  const FestivalByMonth = async () => {
    await axios.get(url + `/pg/devotion/get-all-varat-khathas-post/${FestivalMonthId}`, {

      headers: {
        'authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setFestivals(res.data.data)
    }).catch((err) => {
      console.log("Error::::::::", err)
    })
  }
  useEffect(() => {
    FestivalByMonth()
  }, [])

  if (token && token != 'undefined' ) {
    return (
      <div className='postCountIII'>
        <div className="grid-container">
          {festivals && festivals.length > 0 && festivals.map((data, index) => {
            return <div key={index} style={{ backgroundImage: `url(${data.image})` ,backgroundSize: "cover",
            backgroundPosition: "center center",boxShadow:"inset 0px 0px 0px 200px rgba(0,0,0,0.25)"}} >
              {/* onClick={() => { subCat(data) }} */}

              <div style={{position:"relative",height:"100%"}}>
              <span className='imageAdd'><FontAwesomeIcon icon={faImage} size='sm' style={{color:"white"}} className='imageAddIcon' /></span>
              
              {/* <div className='buttonContainer' style={{width:"100%",height:"70%"}}>
                <div  style={{width:"100%"}}> */}
                  <p className='span' style={{ color: 'white', marginTop: '10px' }}>{data.title_en} 
                  {/* <FontAwesomeIcon icon={faPen} className='icon' /> */}
                  </p>
                  
                  {/* <p className='spanI'>{data.color} <FontAwesomeIcon icon={faPen} className='icon' /></p> */}
                {/* </div> */}
                {/* <div className='box'>
                  <div><button className='box1'>Save</button></div>
                  <div><button className='box2'>Special</button></div>
                  <div><button className='box3'>Disable</button></div>
                </div> */}
              {/* </div> */}
              <div style={{position:"absolute",width:"100%",bottom:"10px"}}>
              <button className='boxbutton' onClick={() => {getContent(data,"en")}}>English</button>
              <button className='boxbutton' onClick={() => {getContent(data,"hi")}}>hindi</button> 
               <button className='boxbutton' onClick={() => {getContent(data,"en","basic")}}>Basic</button>
             
                      </div>
            </div>
</div>
          })}
        </div>
      </div>

    );
  } else {
    window.location.href = '/'
  }
}
