import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
const url = process.env.REACT_APP_BASE_URL

const Index = (props) => {
    const [image, setImage] = React.useState(props.img);
    const [userId, setUserId] = React.useState(props.userId);
    const [data, setData] = React.useState("")
    const [item, setItem] = React.useState([])
    const router = useLocation()
    // console.log('router', router)

    const handlechange = (e) => {
        e.preventDefault();
        setData(e.target.value)
        searchbar(e.target.value)

    }
    const token = `Bearer ${localStorage.getItem("userToken")}`
    const searchbar = async (value) => {
        // console.log('kjdsbd', value, token)
        await axios.get(url + "/user/user-search", {
            params: {
                name: value,
                limit: 10,
                skip: 0
            },
            headers: {
                'authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setItem([...item, ...res.data.data])
        }).catch(err => console.log("Error::::::::", err))

    }

    return (
        <div className='navigationDashboard' style={{ width: "100%" , textTransform: "capitalize" }}>
            <div style={{ zIndex: '1',display:"flex", justifyContent:"center" ,alignItems:"center"}}>
                {image ?<div> <img src={image} alt={'userImage'} style={{ width: '50px', height: '50px',borderRadius:"100%",}} /></div> : ''}{' '}{props.data}

                <p style={{ fontSize: '14px', fontWeight: '400' ,margin:"0",marginLeft:"3px"}}>{userId ?`(${userId})`  : ''}</p>
            </div>
            <div style={{ right: 0 }}>
                {/* {router.pathname.includes('user-accounts-list') && <form action="#" onChange={handlechange}>
                    <button type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                    <input type="text" placeholder="Search" name="search" value={data} onChange={e => e.target.value} style={{ width: "70%" }} />
                </form>} */}
                {/* <UserLists data={item} style={{display: 'none !important'}}/> */}
            </div>
        </div>
    )
}

export default Index