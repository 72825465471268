import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Navigation from "../../../../Navigation";
import Sidebar from "../../../../Side";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ButtonToggle,
} from "reactstrap";

const index = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const history = useLocation();
  // console.log(history)
  const [data, setData] = useState(history.state.data);
  const onDismiss = () => setVisible(false);
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [diable, setdisable] = useState(history.state.data.postStatus)
  const [trendin, setTrend] = useState(history.state.data.
    is_trending
    )
  const URL = process.env.REACT_APP_BASE_URL;

  const formik = useFormik({

    initialValues: {
      categoryId:
        history.state.type == 7 || history.state.type == 8
          ? history.state.data[0].id
          : history.state.data.category_id,
      id: history.state.data.id,
      categoryName: history.state.data.category_name,
      title: history.state.data.title,
      titleHi: history.state.data.title_hi,
      titleEn: history.state.data.title_en,
      images: history.state.data.images,
      month: history.state.data.month,
      nameHi: history.state.data.name_hi,
      nameEn: history.state.data.name_en,
      isDisable: diable,
      isTrending: history.state.data.is_trending,
      content: history.state.type == 7 || history.state.type == 8 ? "<p></p>" : history.state.data.content,
    },

    onSubmit: (values) => {
      if (history.state.type == 1) {
        EditFestivalBasicDetails(values);
      }
      if (history.state.type == 2) {
        EditVratKathaDetails(values);
      }
      if (history.state.type == 3) {
        EditRitualDetails(values);
      }
      if (history.state.type == 4) {
        EditEnglishBlogDetails(values);
      }
      if (history.state.type == 5) {
        EditHindiBlogDetails(values);
      }
      if (history.state.type == 6) {
        EditWellnessDetails(values);
      }
      if (history.state.type == 7) {
        CreateNewHindiBlog(values);
      }
      if (history.state.type == 8) {
        CreateNewEnglishBlog(values);
      }
    },
  });


  const EditFestivalBasicDetails = (values) => {
    // console.log(values)
    fetch(`${URL}/admin/update-festival-post?categoryId=${data.category_id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditRitualDetails = (values) => {
    fetch(`${URL}/admin/update-ritual-post?id=${data.id}&slug=${data.slug}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditEnglishBlogDetails = (values) => {
    values["isDisable"] = diable == 0 ? false : true
    values["isTrending"] = trendin == 0 ? false : true
    // values["slug"] = history.state.
    //   data.slug

    // console.log(values)
    // values.set("isDisable",diable)

    fetch(`${URL}/admin/update-en-blog-post?blogId=${data.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditHindiBlogDetails = (values) => {
    fetch(`${URL}/admin/update-hi-blog-post?blogId=${data.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditWellnessDetails = (values) => {
    fetch(`${URL}/admin/update-wellness-post?postId=${data.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditVratKathaDetails = (values) => {
    fetch(
      `${URL}/admin/update-vrat-katha-post?id=${data.id}&slug=${data.slug}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const CreateNewHindiBlog = (values) => {
    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }
    axios
      .post(`${URL}/admin/create-new-hi-blog`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status == "200") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            //     navigate(-1);
            navigate("/text-editor", {
              state: {
                data: result.data.data,
                language: "language",
                content: result.data.data.content,
                type: 5,
              },
            });

          }, 3000);

        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const CreateNewEnglishBlog = (values) => {
    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }
    axios
      .post(`${URL}/admin/create-new-en-blog`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status == "200") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            //     navigate(-1);
            navigate("/text-editor", {
              state: {
                data: result.data.data,
                language: "language",
                content: result.data.data.content,
                type: 5,
              },
            });

          }, 3000);

        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };

  const name = localStorage.getItem("user");
  const pageTitle = "Edit basic Detail";
  const active = "active";
  if (token && token != "undefined") {
    return (
      <>

        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <section className="postCountIV ">
                <div
                  style={{
                    color: "black",
                    marginBottom: "30px",
                    textAlign: "center",
                    width: "100%",
                    borderRadius: "10px",
                    justifyContent: "center",
                    marginLeft: "10px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h3>{history.state.pageTitle + " basic detail"}</h3>
                </div>

                <Form
                  onSubmit={formik.handleSubmit}
                  style={{ padding: "10px 20px 0px 20px" }}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      {isSuccess && (
                        <Alert color="info" isOpen={visible} toggle={onDismiss}>
                          {message}
                        </Alert>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        {isError && (
                          <Alert
                            style={{ background: "rgb(251, 99, 71)", color: "white" }}
                            isOpen={visible}
                            toggle={onDismiss}
                          >
                            Error::
                            {message}
                          </Alert>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="row">
                      <Row form>
                        <Col>
                          <Label for="name" className="lableCategory">
                            Id/Category Id:
                          </Label>
                          {history.state.type == 7 || history.state.type == 8 ? (
                            <select
                              onChange={(e) => {
                                // setCategory(history.state.data[e.target.selectedIndex].id);
                                formik.values.categoryId =
                                  history.state.data[e.target.selectedIndex].id;
                              }}
                              // values={category}
                              style={{
                                display: "block",
                                width: "100%",
                                fontSize: "16px",
                                fontWeight: "300",
                                borderRadius: "5px",
                                borderColor: "#ced4da",
                                padding: "7px",
                              }}
                            >
                              {history.state.data &&
                                history.state.data.length > 0 &&
                                history.state.data.map((value, index) => {
                                  return <option>{value.category_name}</option>;
                                })}
                            </select>
                          ) : (
                            <Input
                              type="text"
                              name={history.state.type == 1 ? "category_id" : "id"}
                              placeholder={
                                history.state.data
                                  ? history.state.data.category_id ||
                                  history.state.data.id
                                  : `Enter CategoryId`
                              }
                              values={
                                history.state.type == 1
                                  ? formik.values.categoryId
                                  : formik.values.id
                              }
                              disabled={history.state.type == 7 || history.state.type == 8 ? false : true}
                              onChange={formik.handleChange}
                            />
                          )}
                        </Col>
                        <Col>
                          <Label for="name" className="lableCategory">
                            Title:
                          </Label>
                          <Input
                            type="text"
                            name={
                              history.state.type == 4 ||
                                history.state.type == 5 ||
                                history.state.type == 7 || history.state.type == 8
                                ? "title"
                                : "titleEn"
                            }
                            // required
                            // placeholder={`Enter Title `}

                            values={formik.values.title}
                            placeholder={
                              history.state.data
                                ? history.state.data.title_en ||
                                history.state.data.title
                                : `Enter TitleEn`
                            }
                            onChange={formik.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {(history.state.type == 1 ||
                          history.state.type == 2 ||
                          history.state.type == 3) && (
                            <Col>
                              <Label for="name" className="lableCategory">
                                Title Hindi:
                              </Label>
                              <Input
                                type="text"
                                name="titleHi"
                                // placeholder={`Enter Title Hindi`}
                                placeholder={
                                  history.state.data
                                    ? history.state.data.title_hi
                                    : `Enter TitleHi`
                                }
                                values={formik.values.titleHi}
                                onChange={formik.handleChange}
                              />
                            </Col>
                          )}

                        <Col>
                          <Label for="name" className="lableCategory">
                            Slug: {diable?"l;":"jk"}
                          </Label>
                          <Input
                            type="text"
                            name="slug"
                            placeholder={
                              history.state.data
                                ? history.state.data.slug
                                : `Enter Slug`
                            }
                            values={formik.values.slug}
                            onChange={formik.handleChange}
                            disabled={history.state.type == 7 || history.state.type == 8 ? false : true}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col>
                          <Label for="name" className="lableCategory">
                            images:
                          </Label>
                          <Input
                            type={history.state.type == 7 || history.state.type == 8 ? "file" : "name"}
                            name="Image"
                            placeholder={
                              history.state.data
                                ? history.state.data.image
                                : `Enter image Url`
                            }
                            value={formik.values.images}
                            onChange={
                              history.state.type == 7 || history.state.type == 8
                                ? (e) => {
                                  formik.setFieldValue(
                                    "image",
                                    e.currentTarget.files[0]
                                  );
                                }
                                : formik.handleChange
                            }
                          />
                        </Col>
                        {history.state.type == 1 && (
                          <Col>
                            <Label for="name" className="lableCategory">
                              Month:
                            </Label>
                            <Input
                              type="text"
                              name="month"

                              placeholder={
                                history.state.data
                                  ? history.state.data.month
                                  : `Enter status`
                              }
                              values={formik.values.month}
                              onChange={formik.handleChange}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row form>
                        {/* <Col>
                    <label className="lableCategory">
                      {"Disable: "}{" "}
                      <span
                        style={
                          formik.values.isDisable == true
                            ? { backgroundColor: "red" }
                            : { backgroundColor: "green" }
                        }
                        className="select-option"
                      ></span>
                    </label>
                    <div className="col-md-8 mb-2">
                      <select
                        name="isDisable"
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                      >
                      <option value={"Select"}>Select</option>
                      <option value={"true"}>Enable</option>
                      <option value={"false"}>Disable</option>
                      </select>
                    </div>
                  </Col> */}
                        <Col>
                          <label className="lableCategory">
                            {"Disable: "}
                            <span
                              style={
                                formik.values.isDisable
                                  ? { backgroundColor: "green" }
                                  : { backgroundColor: "red" }
                              }
                              className="select-option"
                            ></span>
                          </label>
                          <div className="col-md-8 mb-2">
                            <div className="toggle-btn">
                              <div className={diable === 1 && "yes"} onClick={() => {
                                if (diable === 0) {
                                  setdisable(1)
                                } else {
                                  setdisable(0)
                                }
                              }}>Yes</div>
                              <div onClick={() => {
                                if (diable === 0) {
                                  setdisable(1)
                                } else {
                                  setdisable(0)
                                }
                              }} className={diable === 0 && "no"}>No</div>
                            </div>
                            {/* <select
                              name="isDisable"
                              onChange={(e) => {
                                e.target.value == "true"
                                  ? (formik.values.isDisable = true)
                                  : (formik.values.isDisable = false);
                              }}
                              {formik.handleChange}
                              style={{ width: "100%" }}
                            >
                          
                              <option>Select</option>
                              <option value={true}>Enable</option>
                              <option value={false}>Disable</option>
                            </select> */}
                          </div>
                        </Col>
                        <Col>
                          <label className="lableCategory">
                            {"Trending: "}
                            <span
                              style={
                                formik.values.
isTrending                                
                                  ? { backgroundColor: "green" }
                                  : { backgroundColor: "red" }
                              }
                              className="select-option"
                            ></span>
                          </label>
                          <div className="col-md-8 mb-2">
                            <div className="toggle-btn">
                              <div className={trendin && "yes"} onClick={() => {
                                if (trendin) {
                                  setTrend(false)
                                } else {
                                  setTrend(true)
                                }
                              }}>Yes</div>
                              <div onClick={() => {
                                if (!trendin) {
                                  setTrend(true)
                                } else {
                                  setTrend(false)
                                }
                              }} className={!trendin  && "no"}>No</div>
                            </div>
                            {/* <select
                              name="isDisable"
                              onChange={(e) => {
                                e.target.value == "true"
                                  ? (formik.values.isDisable = true)
                                  : (formik.values.isDisable = false);
                              }}
                              {formik.handleChange}
                              style={{ width: "100%" }}
                            >
                          
                              <option>Select</option>
                              <option value={true}>Enable</option>
                              <option value={false}>Disable</option>
                            </select> */}
                          </div>
                        </Col>
                        {/* <Col>
                          <label className="lableCategory">
                            {"Special: "}{" "}
                            <span
                              style={
                                formik.values.is_special
                                  ? { backgroundColor: "green" }
                                  : { backgroundColor: "red" }
                              }
                              className="select-option"
                            ></span>
                          </label>

                          <div className="col-md-8 mb-2">


                            <select
                              name="is_special"
                              onChange={formik.handleChange}
                              style={{ width: "100%" }}
                            >
                              <option>Select</option>
                              <option value={true}>Enable</option>
                              <option value={false}>Disable</option>
                            </select>
                          </div>
                        </Col> */}
                        {(history.state.type == 4 ||
                          history.state.type == 5 ||
                          history.state.type == 7 || history.state.type == 8) && (
                            <Col>
                              <label className="lableCategory">
                                {"Trending: "}{" "}
                                <span
                                  style={
                                    formik.values.isTrending
                                      ? { backgroundColor: "green" }
                                      : { backgroundColor: "red" }
                                  }
                                  className="select-option"
                                ></span>
                              </label>
                              <div className="col-md-8 mb-2">
                                <select
                                  name="isTrending"
                                  onChange={(e) => {
                                    e.target.value == "true"
                                      ? (formik.values.isTrending = true)
                                      : (formik.values.isTrending = false);
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  <option>Select</option>
                                  <option value={"true"}>Enable</option>
                                  <option value={"false"}>Disable</option>
                                </select>
                              </div>
                            </Col>
                          )}
                      </Row>
                    </div>
                  </div>

                  <Button
                    style={{
                      display: "inline-block",
                      width: "200px",
                      float: "right",
                      marginTop: "15px",
                      background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                    }}
                    type="submit"
                  >
                    <strong>Change Basic Detail</strong>
                  </Button>
                </Form>
              </section>
            </div>
          </div>
        </div>



      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
