import React from 'react';
import { useLocation } from "react-router-dom";
import Sidebar from '../Side';
import Navigation from '../Navigation';
import SingleDetails from './SingleDetails'

const Index = (props) => {
    let location = useLocation();
    const data = location.state;
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("user")
    const pageTitle = location.state.name ;
    const userImageUrl = location.state.avatar;
    const userId = location.state.affiliate_id;
    const active = 'active'
    if(token){
        return (
            <>
                 <div className="main-parentcontainer">
                    <div className="grid-parent">
                    <Sidebar data={name} ugc={active} />
                        <div className='grid-child'> <Navigation data={pageTitle} img={userImageUrl} userId={userId}/>
                        <SingleDetails data={data}/></div>
                    </div></div>
                
               
            </>
        )
        }else{
            window.location.href = '/';
        }
    }

export default Index