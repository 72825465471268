import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import Data from "../../../../Data";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const url = process.env.REACT_APP_BASE_URL;

export default function FullWidthGrid(props) {
  // modal open
  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen(!open);

  const [festivals, setFestivals] = useState([]);
  const history = useNavigate();

  // const token = localStorage.getItem("token");
  const FestivalMonthId = props.data;
  function subCat(data) {
    history("/sub-festival", { state: data });
  }
  function redirectedit(data) {
    history("/festival-category-update", { state: data });
  }
  function reDirect(data) {
    history("/add-festivals", { state: data });
  }
  const token = `Bearer ${localStorage.getItem("token")}`;

  const FestivalByMonth = async () => {
    await axios
      .get(url + "/pg/festival/festival-by-month", {
        params: {
          monthId: FestivalMonthId,
        },
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json", "ngrok-skip-browser-warning": "any"
        },
      })
      .then((res) => {
        setFestivals(res.data.data);
      })
      .catch((err) => {
        console.log("Error::::::::", err);
      });
  };
  useEffect(() => {
    FestivalByMonth();
  }, [props.data]);

  if (token && token != "undefined") {
    return (
      <div className="postCountIII">
        <div className="add-f-parent">
          <a src="/add-festivals" onClick={() => reDirect(props.data)}>
            <button
              style={{
                cursor: "pointer",
                border: "2px solid gray",
                borderRadius: "10px",
                justifyContent: "flex-end",
                marginRight: "0px",
                marginBottom: "8px"
              }}
              onClick={() => reDirect(props.data)} >Add Festivals  <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}</button></a>
        </div>
        <div className="grid-container">
          {festivals &&
            festivals.length > 0 &&
            festivals.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(${data.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.42)",
                  }}

                >
                  { }
                  <span style={{ cursor: "normal" }} onClick={() => { redirectedit(data) }} className="imageAdd">
                    <FontAwesomeIcon

                      icon={faPen}
                      size="sm"
                      style={{ color: "white" }}
                      className="imageAddIcon"
                    />
                  </span>
                  <div onClick={() => {
                    subCat(data);
                  }} className="buttonContainer">
                    <div>
                      <p
                        className="span"
                        style={{ color: "white", marginTop: "10px" }}
                      >
                        {data.name_en}
                        {/* <FontAwesomeIcon icon={faPen} className='icon' /> */}
                      </p>
                      {/* <p className='spanI'>{data.color} <FontAwesomeIcon icon={faPen} className='icon' /></p> */}
                    </div>
                    {/* <div className='box'>
                  <div><button className='box1'>Save</button></div>
                  <div><button className='box2'>Special</button></div>
                  <div><button className='box3'>Disable</button></div>
                </div> */}
                  </div>
                </div>
              );
            })}
        </div>

      </div>
    );
  } else {
    window.location.href = "/";
  }
}
