import React from 'react'
import Sidebar from '../../../Side'
import Navigation from '../../../Navigation'
import RitualsCategory from './RitualsCategory'
const index = () => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("user")
    const pageTitle = 'Rituals';
    const active = 'active';
    if(token){
    return (
        <>
            <div className="main-parentcontainer">
                <div className="grid-parent">
                <Sidebar data={name} pgc={active} />
                        <div className='grid-child'><Navigation data={pageTitle} />
                        <RitualsCategory /></div>
                    </div></div>
            
            
        </>
    )}
    else{
        window.location.href = '/'
    }
}

export default index
