import React from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Button, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from 'react-router-dom';
const url = process.env.REACT_APP_BASE_URL


const Index = (props) => {
    const [aartis, setAartis] = useState([])
    const history = useNavigate();

    // function subCat() {
    //     history('/editMantraDetails', { state: festivals })
    // }


    const token = `Bearer ${localStorage.getItem("token")}`
    // const id = props.data
    const id = props.data

    const FestivalByMonth = async () => {
        await axios.get(url + `/pg/rgyan-audio/god-aarti-post/${id.id}?language=en`,
            {


                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                setAartis(res.data.data[0])

            }).catch((err) => {
                console.log("Error::::::::", err)
            })
    }
    useEffect(() => {
        FestivalByMonth()
    }, [])


    if (token && token != 'undefined' ) {
        return (
            <section className='postCountIV '>


                <div className="grid-container" style={{ height: "80vh", justifyContent: "space-around", textAlign: "left" }}>


                    <div style={{ border: "2px solid black", width: "65vw", height: "75.2vh", padding: '10px' }}>




                        <p><b >Aarti Title:</b><span style={{ marginLeft: "130px" }}>{(aartis || {}).title_en}</span></p>

                        <p><b style={{ marginLeft: "10px" }}>Image:</b> <img src={aartis.image} alt="image" style={{ width: "20vw", marginLeft: "150px" }} /></p>
                        <p><b style={{ marginLeft: "10px" }}>Audio: </b> <audio src={aartis.audio_url} controls autoplay style={{ marginLeft: "150px" }} /></p>



                        <div style={{ display: "flex", flexWrap: "wrap", height: "45%", flexDirection: "column", width: "100%" }}>

                            <div style={{ width: "20%", height: "100%", marginTop: "50px" }}>
                                <p><b style={{ marginLeft: "10px" }}>Aarti :</b> </p>

                            </div>

                            <div style={{ overflowY: 'scroll', height: "100%", width: "80%", }}>

                                <span dangerouslySetInnerHTML={{ __html: (aartis || {}).mantra_en }} style={{ display: 'inline-block', marginLeft: "60px" }}></span>

                            </div>
                        </div>


                        {/* <Button style={{ display: 'inline-block', width: "100px", float: 'right' }} type="submit" onClick={()=>{subCat()}}><strong>Edit</strong></Button> */}



                    </div>



                </div>


            </section>
        );
    } else {
        window.location.href = '/'
    }
}

export default Index