
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";


const URL = process.env.REACT_APP_BASE_URL;

export default function FullWidthGrid(props) {
    const [seo, setSEO] = useState([]);
    const [display, setDisplay] = useState(false);
    const navigate = useNavigate()
    const HandleRedirect = (value, type) => {
        value.type = type
        value.componentName = props.name
        navigate('/add-seo-festival',
            {
                state: value
            })
    }
    const getCategory = async () => {
        await axios.get(URL + "/admin/data-without-seo", {
            params: {
                type: props.data
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((result) => {
            setDisplay(true)
            setSEO(result.data.data)
        }).catch((err) => {
            console.log("Error::::::::", err)

        })
    }
    useEffect(() => {
        getCategory()
    }, []);
    return (
        <>
            {/* <div>
                <button style={{ cursor: 'pointer', border: "2px solid gray", borderRadius: "10px", justifyContent: "flex-end", marginRight: "0px", float: "right" }} onClick={() => { HandleRedirect() }}> ADD New SEO<FontAwesomeIcon icon={faAngleDoubleRight} /> </button>
            </div> */}
            <table
                class="table table-striped table-bordered"
                style={{
                    borderRadius: '3px',
                    padding: "5px",
                    backgroundColor: 'white',
                    width: "fitContent"
                }}
            >
                <thead>
                    <tr colspan="14">
                        <th colspan="2" >
                            S.N
                        </th>
                        <th colspan="2" >
                            ID
                        </th>
                        <th colspan="2" >
                            Name Engilsh
                        </th>
                        <th colspan="2" >
                            Name Hindi
                        </th>
                        <th colspan="2" >
                            Add
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {display && seo.length != 0 && seo.map((item, index) => {
                        return <tr key={index}>
                            <td colspan='2'>
                                {index + 1}
                            </td> <td colspan='2'>
                                {item.id}
                            </td> <td colspan='2'>
                                {item.name_en}
                            </td> <td colspan='2'>
                                {item.name_hi}
                            </td>
                            <td style={{ cursor: 'pointer' }} onClick={() => { HandleRedirect(item, props.data) }}> Add </td>
                        </tr>
                    })
                    }
                    {display && seo.length == 0 &&

                        <Alert style={{ background: "rgb(251, 99, 71)", color: "white", marginTop: "50px" }}>Data Not Found !!

                        </Alert>
                    }


                </tbody>

            </table>
        </>
    );
}
