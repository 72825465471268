import React from 'react';
import Tabs from '../../../Tabs/Tabs';
import Astrology from '../../Divotion/VartkathaAllCat'

const VratKathaCategory = () => {

  let Astrologys  = [
    {id:2, name: "Weekly Vrat Katha" }, {id:4, name: "Ekadashi Vrat katha" }, {id:1, name: "Monthly Vrat katha" }, {id:3, name: "Yearly Vrat Katha" }]
  



  return (

    <section className='postCountIV'>
      <Tabs>
        {Astrologys && Astrologys.length>0 && Astrologys.map((items, index) => {
          return <div key={index} label={items.name} >
            <Astrology data={items.id}/>
          </div>

        })}
      </Tabs>
    </section>








    // <section className='postCountIV'>
    //   <Tabs>
    //     <div label="Masik Vrat Katha">
    //       <Astrology />
    //     </div>
    //     <div label="Weekly Vrat katha">
    //       <Astrology />
    //     </div>
    //     <div label="Weekly Vrat katha">
    //       <Astrology />
    //     </div>
    //     <div label="Ekadashi Vrat Katha">
    //       <Astrology />
    //     </div>
    //   </Tabs>
    // </section>
  )
}

export default VratKathaCategory