import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import "../App.css";
import { Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";

const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const RichTextEditor = ({
  getValue,
  setData,
  language,
  type,
  state,
}) => {
  const navigate = useNavigate();
  const editor = useRef(null);
  const [context, setContext] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const onDismiss = () => setVisible(false);
  const [visible, setVisible] = useState(true);
  const [success, setSuccess] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const URL = process.env.REACT_APP_BASE_URL;
const editors=()=>{const edit = document.getElementsByClassName("jodit-ui-group__source")}

  useEffect(()=>{
editors()
  },[])
  const UpdateWellness = (context) => {
    let con = { contentEn: context }
    fetch(
      `${URL}/admin/update-wellness-post?postId=${state.data.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(con),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setSuccess("Content Successfully updated");
          setTimeout(() => {
            setIsSuccess(false);
            setSuccess("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setError("");
          navigate(-1);
        }, 3000);
      });
  };

  const UpdateEnglishBlog = (context) => {
    let con = { content: context }
    fetch(
      `${URL}/admin/update-en-blog-post?blogId=${state.data.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(con),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setSuccess("Content Successfully updated");
          setTimeout(() => {
            setIsSuccess(false);
            setSuccess("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setError("");
          navigate(-1);
        }, 3000);
      });
  };

  const UpdateHindiBlog = (context) => {
    let con = { content: context }
    fetch(
      `${URL}/admin/update-hi-blog-post?blogId=${state.data.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(con),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setSuccess("Content Successfully updated");
          setTimeout(() => {
            setIsSuccess(false);
            setSuccess("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setError("");
          navigate(-1);
        }, 3000);
      });
  };

  const UpdateFestivalPostData = (context) => {

    let con =
      state.language == "hi"
        ? { contentHi: context }
        : { contentEn: context };
    fetch(
      `${URL}/admin/update-festival-post?categoryId=${state.data.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(con),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setSuccess("Content Successfully updated");
          setTimeout(() => {
            setIsSuccess(false);
            setSuccess("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setError("");
          navigate(-1);
        }, 3000);
      });
  };

  const UpdateVratKathData = (context) => {
    let con =
      state.language == "hi"
        ? { contentHi: context }
        : { contentEn: context };
    fetch(
      `${URL}/admin/update-vrat-katha-post?id=${state.content.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(con),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setSuccess("Content Successfully updated");
          setTimeout(() => {
            setIsSuccess(false);
            setSuccess("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  }

  const UpdateRitualPostData = (context) => {
    let con =
      state.language == "hi"
        ? { contentHi: context }
        : { contentEn: context };
    fetch(
      `${URL}/admin/update-ritual-post?id= ${state.content.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(con),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setSuccess("Content Successfully updated");
          setTimeout(() => {
            setIsSuccess(false);
            setSuccess("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  }

  return (
    <>
      {isSuccess && (
        <Alert
          color="info"
          isOpen={visible}
          toggle={onDismiss}
          style={{
            position: "absolute",
            zIndex: "2",
            right: "0px",
            bottom: "0px",
            margin: "10px",
          }}
        >
          {success}
        </Alert>
      )}
      {isError && (
        <Alert
          style={{ background: "rgb(251, 99, 71)", color: "white" }}
          isOpen={visible}
          toggle={onDismiss}
        >
          Error::
          {error}
        </Alert>
      )}
      <JoditEditor
        ref={editor}
        value={
          state.language == "hi"
            ? state.content.content_hi
            : state.content.content_en || state.content
        }
        config={config}
        tabIndex={1}
        onChange={(newContent) => {
          setContext(newContent);
          getValue(newContent);
        }}
      />
      <button
        style={{
          zIndex: "2",
          right: "150px",
          margin: "5px",
          top: "0px",
          position: "absolute",
          width: "113px",
          height: "34px",
          background: "orange",
          border: "none",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "14px",
          fontWeight: "bold",
          color: "black",
        }}
        onClick={() => {
          if (type == 1) {
            UpdateFestivalPostData(context);
          }
          if (type == 2) {
            UpdateVratKathData(context);
          }
          if (type == 3) {
            UpdateRitualPostData(context)
          }
          if (type == 4) {
            UpdateEnglishBlog(context)
          }
          if (type == 5) {
            UpdateHindiBlog(context)
          }
          if (type == 6) {
            UpdateWellness(context)
          }
        }}
      >
        Submit
      </button>
    </>
  );
};

export default RichTextEditor;
