import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import {
    FormGroup, Label, Input,
    Button, Form
} from 'reactstrap';
import { post, patch } from 'axios';
const url = process.env.REACT_APP_BASE_URL

const AddCategoryForm = () => {
    const token = `Bearer ${localStorage.getItem("token")}`;

    const addCategoryForm = async (values) => {
        try {
            const res = await post(url + "/category/add-parent-category", values,
                {
                    headers: {
                        'Authorization': token
                    },
                })
        } catch (err) {
            console.log("Error::::::::", err);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            parent_id: 0,
        },
        onSubmit: (values) => {
            addCategoryForm(values);
        }
        , validationSchema: () =>
            Yup.object().shape({
                name: Yup.string()
                    .required("Category name is required")
                    .min(3, "Please enter you full name."),
            }),
    })

    const inputError = (formik, input) => {
        return (
            formik.errors[input] &&
            formik.touched[input] && (
                <div
                    style={{
                        color: "rgb(235, 54,54)",
                        fontSize: "14px",
                    }}
                >
                    {formik.errors[input]}
                </div>
            )
        );
    };

    return (
        <div style={{
            display: 'block', width: 1100, padding: '10px 20px 0px 20px'
        }}>
            <Form onSubmit={formik.handleSubmit}>
                <FormGroup style={{ display: 'inline-block', width: '85%' }}>
                    <Label for="name" className='lableCategory'>Category Name:</Label>
                    <Input type="name" name="name" id="name" placeholder={`Enter Category Name`} onChange={formik.handleChange} />
                    {inputError(formik, "name")}
                </FormGroup>
                <Button style={{ display: 'inline-block', width: "13%", float: 'right', marginTop: '32px' }} type="submit"><strong>Add Category</strong></Button>
            </Form>
        </div >
    )
}

export default AddCategoryForm