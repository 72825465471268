import React from "react";
import Sidebar from "../../Admin/Side";
import Navigation from "../../Admin/Navigation";
import SubUploadImage from "./SubUploadImage";
const Index = () => {
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Upload Image";
  const active = "active";
  if (token) {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} other={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <SubUploadImage />
            </div>
          </div>
        </div>

        {/* <Navigation data={pageTitle} /> */}
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
