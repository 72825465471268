import React from 'react';
import { Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import data1 from '../../../Data';
const img = require('../../../../assets/img/profile_pic.jpg');

const ReportList = () => {
    const data = data1.data1;
    return (
        <section className='postCountI'>
            <table className='userList'>
                <thead className='thead'>
                    <tr>
                        <th>Profile Photo/Name</th>
                        <th>User ID</th>
                        <th>Short URL</th>
                        <th>Date</th>
                        <th>Violation</th>
                        <th>Report by</th>
                        <th>Total no. of Report</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((data => {
                        return <tr>
                            <td>
                                <img src={img} alt='profilePic' className='profilePic' /><p className='profileName'>{data.name}</p>
                            </td>
                            <td>{data.userId}</td>
                            <td>{data.shortURL}</td>
                            <td>{data.date}</td>
                            <td>{data.violation}</td>
                            <td>{data.reportBy}</td>
                            <td>{data.noOfReport}</td>
                            <td>
                                <Navbar light expand="lg" className='newUserDropdown'>
                                    <Nav navbar>
                                        <UncontrolledDropdown setActiveFromChild>
                                            <DropdownToggle tag="b" caret>
                                                Action
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem tag="a" href="#">Banned</DropdownItem>
                                                <DropdownItem tag="a" href="#">False</DropdownItem>
                                                <DropdownItem tag="a" href="#">Delete</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>
                                </Navbar>
                            </td>
                        </tr>
                    }))}
                </tbody>
            </table>
        </section>
    )
}

export default ReportList