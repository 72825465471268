import React from 'react';
import Tabs from '../../../Tabs/Tabs';
import Chalisa from '../../ChalisaAllCat'

const ChalisaCategory = () => {
  

    let Chalisas  = [
      {id:306, name: "God Chalisa" }]
  
  
  
    return (
  
      <section className='postCountIV'>
      <Tabs>
        {Chalisas && Chalisas.length>0 && Chalisas.map((items, index) => {
          return <div key={index} label={items.name} >
            <Chalisa data={items.id}/>
          </div>
  
        })}
      </Tabs>
    </section>






    // <section className='postCountIV'>
    //   <h2>Chalisa</h2>
    //   {/* <Tabs> */}
    //     <div label="God Aarti">
    //       <span className='h6 h7'>God Chalisa</span>
    //       <Astrology />
    //     </div>
    //     {/* <div></div> */}
    //   {/* </Tabs> */}
    // </section>
  )
}
export default ChalisaCategory