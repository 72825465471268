import React from 'react';
import Tabs from '../../../Tabs/Tabs';
import Mantra from '../../ChantLife/MantraAllCat'

const MantraCategory = () => {


  let Mantras  = [
    {id:97, name: "Daily Mantras" }, {id:96, name: "God Mantras" }]
  



  return (

    <section className='postCountIV'>
    <Tabs>
      {Mantras && Mantras.length>0 && Mantras.map((items, index) => {
        return <div key={index} label={items.name} >
          <Mantra data={items.id}/>
        </div>

      })}
    </Tabs>
  </section>




    
  )
}

export default MantraCategory