import React from 'react';
import Sidebar from '../Side'
import Navigation from '../Navigation'
import SubCategory from './SubCategory'
const Index = () => {
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("user")
    const pageTitle = 'Categories'
    const active = 'active'
    if(token){
    return (
        <>
             <div className="main-parentcontainer">
                <div className="grid-parent">
                <Sidebar data={name} ugc={active}/>
                        <div className='grid-child'>  <Navigation data={pageTitle} />
                        <SubCategory /></div>
                    </div></div>
         
          
        </>
    )
    }else{
        window.location.href = '/';
    }
}

export default Index
