import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../Admin/Side";
import Navigation from "../../Admin/Navigation";
const URL = process.env.REACT_APP_BASE_URL;

const Index = () => {
  const token = localStorage.getItem("token");
  const history = useNavigate();
  const name = localStorage.getItem("user");
  const [blogCategory, setBlogCategory] = useState([]);
  const [englishBlog, setEnglishBlog] = useState([]);
  const pageTitle = "Wellness";
  const active = "active";
  const [eid, setId] = useState("1");
  const [skip, setSkip] = useState(0);

  const getCategory = async (data, language, basic) => {
    await axios
      .get(`${URL}/pg/wellness/list-of-posts-by-slug?slug=${data.slug}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (basic) {
          history("/basic-details", {
            state: {
              data: result.data.data,
              language: language,
              pageTitle: "Wellness",
              type: 6,
            },
          });
        } else {
          history("/text-editor", {
            state: {
              data: data,
              language: language,
              content: result.data.data.description,
              type: 6,
            },
          });
        }
      });
  };
  const changeStyle = (id) => {
    setId(id);
  };
  const BlogsData = async (params1) => {
    await axios
      .get(
        `${URL}/pg/wellness/list-of-posts?slug=${params1}&limit=200&skip=0`,
        {
          params: {
            categorySlug: params1,
            skip: skip,
            limit: 200,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            client: 3,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setEnglishBlog(res.data.data);
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  useEffect(() => {
    BlogsData("yoga");
  }, []);
  if (token && token != "undefined") {
    return (
      <div>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} pgc={active} />

            <div className="grid-child">
              <Navigation data={pageTitle} />
              <div
                className="postCount"
                style={{  cursor: "pointer" ,overflow:"hidden"}}
              >
                <div className="topbar">
                <p
                  className={["1" == eid ? "BlogNavBar1" : "BlogNavBar"]}
                  onClick={() => {
                    changeStyle("1");
                    BlogsData("yoga");
                  }}
                >
                  Yoga
                </p>
                <p
                  className={["2" == eid ? "BlogNavBar1" : "BlogNavBar"]}
                  onClick={() => {
                    changeStyle("2");
                    BlogsData("healing");
                  }}
                >
                  Healing
                </p>
                <p
                  className={["3" == eid ? "BlogNavBar1" : "BlogNavBar"]}
                  onClick={() => {
                    changeStyle("3");
                    BlogsData("health");
                  }}
                >
                  Health
                </p></div>
                <div className="postCountIII">
                  <div className="grid-container">
                    {englishBlog &&
                      englishBlog.length > 0 &&
                      englishBlog.map((element, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              backgroundImage: `url(${element.image})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              boxShadow:
                                "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                            }}
                          >
                            <div className="d-flex flex-column full-height justify-content-between" >
                              <span className="imageAdd width-full">
                                <FontAwesomeIcon
                                  icon={faImage}
                                  size="sm"
                                  style={{ color: "white" }}
                                  className="imageAddIcon"
                                />
                              </span>
                              <div className="d-flex   flex-column">

                              <p className="spanP">
                                <strong>{element.title}</strong>
                              </p>
                              <div className="width-full mb-1">
                                <button
                                  className="boxbutton"
                                  onClick={() => {
                                    getCategory(element, "en");
                                  }}
                                >
                                  {" "}
                                  Content{" "}
                                </button>
                                <button
                                  className="boxbutton"
                                  onClick={() => {
                                    getCategory(element, "en", "basic");
                                  }}
                                >
                                  basic
                                </button>
                              </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
