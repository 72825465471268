import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
// import React, { useState, useEffect } from 'react'

const URL = process.env.REACT_APP_BASE_URL;

export default function FullWidthGrid(props) {

    const [seo, setSEO] = React.useState([]);
    const navigate = useNavigate()

    const HandleRedirectSeo = (value, type) => {
        value.type = type
        value.componentName = props.name
        navigate('/edit-seo',
            {
                state: value
            })
    }
    const getCategory = async () => {
        await axios.get(URL + "/admin/all-seo-data", {
            params: {
                type: props.data
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            }
        }).then((result) => {
            setSEO(result.data.data)
        })
    }
    React.useEffect(() => {
        getCategory()
    }, []);
    return (
        <>
            <table
                class="table table-striped table-bordered"
                style={{
                    borderRadius: '3px',
                    padding: "5px",
                    backgroundColor: 'white',
                    width: "fitContent"
                }}
            >
                <thead className='special-head2'>
         

                    <tr colspan="14">
                        <th colspan="2" >
                            S.N
                        </th>
                        <th colspan="2" >
                            Title
                        </th>
                        <th colspan="2" >
                            Title Hindi
                        </th>
                        <th colspan="2" >
                            Meta Description
                        </th>
                        <th colspan="2" >
                            Meta Keyword
                        </th>
                        <th colspan="2" >
                            Meta Keyword Hindi
                        </th>
                        <th colspan="2" >
                            Edit
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {seo && seo.length > 0 && seo.map((data, index) => {
                        return <tr key={index}>

                            <td colspan="2">
                                {index + 1}
                            </td>
                            <td style={{ height: "100px" }} colspan="2">{data.title}</td>
                            <td colspan="2">{data.title_hi}</td>
                            <td colspan="2">{data.meta_description}</td>
                            <td colspan="2">{data.meta_keyword}</td>
                            <td colspan="2">{data.meta_keyword_hi}</td>
                            <td style={{ cursor: 'pointer' }} onClick={() => { HandleRedirectSeo(data, props.data) }}>Edit</td>
                        </tr>
                    })}
                </tbody>

            </table>


        </>
    );
}
