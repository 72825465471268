import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
    FormGroup, Label, Input,
    Button, Form
} from 'reactstrap';
import { patch } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
const url = process.env.REACT_APP_BASE_URL;
const img = require('../../../../../assets/img/dddd.webp');

const Index = (props) => {

    const [displayMsg, setDisplayMsg] = useState('');
    const history = useNavigate();
    const categoryDetails = props.data;
    let parentCategoryName;
    if (categoryDetails.parent_id === 1) {
        parentCategoryName = 'Astrology'
    } else if (categoryDetails.parent_id === 7) {
        parentCategoryName = 'Suvichar'
    } else if (categoryDetails.parent_id === 15) {
        parentCategoryName = 'Holy Places'
    } else if (categoryDetails.parent_id === 23) {
        parentCategoryName = 'Health'
    } else if (categoryDetails.parent_id === 33) {
        parentCategoryName = 'Spiritual'
    } else if (categoryDetails.parent_id === 39) {
        parentCategoryName = 'Arts & Society'
    } else if (categoryDetails.parent_id === 45) {
        parentCategoryName = 'Festival'
    }

    const handleFormValues = async (values) => {
        try {
            const formdata = new FormData();
            for (let key in values) {
                formdata.append(key, values[key]);
            }
            const res = await patch(url + "/category/edit-category-info", formdata,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    }
                }).then((res) => {
                    // console.log('ressssssssss', res)
                    if (res.data.status == "success") {
                        setDisplayMsg(true)
                        setTimeout(() => {
                            setDisplayMsg(false)
                            history('/categories')
                        }, 2000);

                    }
                })
        } catch (err) {
            console.log("Error::::::::", err);
            setDisplayMsg(false)

        }
    }

    const formik = useFormik({
        initialValues: {
            id: categoryDetails.id,
            name: categoryDetails.name,
            color: null,
            image: null,
            parent_id: null,
            isSpecial: null,
            isDisable: null,

        },
        onSubmit: (values) => {
            handleFormValues(values);
        }
    })
    return (
        <section className='postCountIV' style={{overflow:"auto", boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <div style={{
                display: 'block', padding: 20
            }}>
                <Form onSubmit={formik.handleSubmit}>
                    <FormGroup>
                        <Label for="name" className='lableCategory'>Name : {categoryDetails.name} </Label>
                        <Input type="name" name="name" id="name" placeholder={`Change ${categoryDetails.name} Category Name`} onChange={formik.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="color" className='lableCategory'>Color : {categoryDetails.color}</Label>
                        <Input type="color" name="color" id="color" value={categoryDetails.color} onChange={formik.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="image" className='lableCategory'>Image : <img src={categoryDetails.image === "null" ? img : categoryDetails.image} alt="img" style={{ backgroundColor: 'grey', width: '100px', height: '100px', borderRadius: '10px', padding: '10px', margin: '0px 50px' }} /></Label>
                        <Input type="file" name="image" id="image" placeholder={`Change ${categoryDetails.image} Category Image`} onChange={(event) => {
                            formik.setFieldValue(
                                'image',
                                event.currentTarget.files[0]
                            );
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="radio" className='lableCategory'>Special : {categoryDetails.is_disable ? "True" : "False"}</Label><br />
                        <Input type="radio" name="isSpecial" id="radio" value={true} placeholder={`Change ${categoryDetails.is_special} Category Image`} onChange={formik.handleChange} />{" True "}
                        <Input type="radio" name="isSpecial" id="radio" value={false} placeholder={`Change ${categoryDetails.is_special} Category Image`} onChange={formik.handleChange} />{" False "}
                    </FormGroup>
                    <FormGroup>
                        <Label for="radio" className='lableCategory'>Disable : {categoryDetails.is_disable ? "True" : "False"}</Label><br />
                        <Input type="radio" name="isDisable" id="radio" value={true} placeholder={`Change ${categoryDetails.is_special} Category Image`} onChange={formik.handleChange} />{" True "}
                        <Input type="radio" name="isDisable" id="radio" value={false} placeholder={`Change ${categoryDetails.is_special} Category Image`} onChange={formik.handleChange} />{" False "}
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleSelect" className='lableCategory'>Parent Category : {parentCategoryName}</Label>
                        <Input type="select" name="parent_id" id="exampleSelect" onChange={formik.handleChange}>
                            <option value='1'>Astrology</option>
                            <option value='7'>Suvichar</option>
                            <option value='15'>Holy Places</option>
                            <option value='23'>Health</option>
                            <option value='33'>Spiritual</option>
                            <option value='39'>Arts & Society</option>
                            <option value='45'>Festival</option>
                        </Input>
                    </FormGroup>
                    <Button style={{ display: 'inline-block', width: "150px", float: 'right', color: 'black' }} type="submit"><strong>Submit</strong></Button>
                </Form>
            </div >
            {displayMsg && <div className="displayMsg">Successful</div>}
        </section >
    )
}

export default Index