import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import './App.css';
import "./App.css";

// Components
import HomePage from "./components/Home";
import Profile from "./components/Profile";
import About from "./components/About";
import Dashboard from "./components/Admin/Dashboard";
import UserAccountList from "./components/Admin/UserAccounts";
import UserAccountDetails from "./components/Admin/UserAccounts/UserAccountDetails";
import ReportAccounts from "./components/Admin/Reports/ReportAccount";
import ReportAccountDetail from "./components/Admin/Reports/ReportAccount/ReportAccountDetail";
import ReportPosts from "./components/Admin/Reports/Post";
import ReportShorts from "./components/Admin/Reports/Short";
import ReportHashTags from "./components/Admin/Reports/HashTags";
import Categories from "./components/Admin/Categories";
import Festivals from "./components/Admin/PGC/Festivals";
import FestivalscategoryUpdate from "./components/Admin/PGC/FestivalscategoryUpdate";
import AddFestivals from "./components/Admin/PGC/AddFestivals";
import Rituals from "./components/Admin/PGC/Divotion/Rituals";
import VratKatha from "./components/Admin/PGC/Divotion/VratKatha";
import Mantra from "./components/Admin/PGC/ChantLife/Mantra";
import Chalisa from "./components/Admin/PGC/ChantLife/Chalisa";
import Aarti from "./components/Admin/PGC/ChantLife/Aarti";
import HinduFestivals from "./components/Admin/PGC/Video/HinduFestivals";
import Temples from "./components/Admin/PGC/Video/Temples";
import VratKathaVideo from "./components/Admin/PGC/Video/VratKatha";
import PrayersMantras from "./components/Admin/PGC/Video/PrayersMantras";
import PopularVideo from "./components/Admin/PGC/Video/PopularVideo";
import More from "./components/Admin/PGC/Video/More";
import Blogs from "./components/Admin/PGC/Blogs";
import Video from "./components/Admin/PGC/Video";
import SingleCategoryDetails from "./components/Admin/Categories/SingleCategoryDetails";
import Test from "./components/Admin/Categories/Category";
import AddCategory from "./components/Admin/Categories/AddSubCategory";
import Form from "./components/Form";
import FestivalSub from "./components/Admin/PGC/Tabs/FestivalSubCategory";
import AddFestivalSub from "./components/Admin/PGC/AddFestivalSub";
import AartiSubCat from "./components/Admin/PGC/ChantLife/AartiSubCat";
import TextEditor from "./components/TextEditor";
import PlayVideo from "./components/playVideo";
import NewUserList from "./components/Admin/NewUserList";
import LiveDarshan from "./components/Admin/LiveDarshan";
import EditLiveDarshan from "./components/Admin/LiveDarshan/EditLiveDarshan";
import AddLiveDarshan from "./components/Admin/LiveDarshan/AddLiveDarshan";
import LiveFestival from "./components/Admin/Festival";
import EditSeoFestival from "./components/Admin/Festival/EditSeoFestival";
import AddSeoFestival from "./components/Admin/Festival/AddSeoFestival";
import CatagorySeo from "./components/Admin/Festival/CatagorySeo";
import WithOutSeo from "./components/Admin/WithOutSeo/index";
import EditVideo from "./components/Admin/PGC/Video/EditVideo";
import AddVideo from "./components/Admin/PGC/Video/AddVideo";
import ChantLife from "./components/Admin/ChantLife/index";
import EditChantLife from "./components/Admin/ChantLife/EditChantLife";
import AddChantLifePost from "./components/Admin/ChantLife/AddChantLife";
import BasicDetail from "./components/Admin/PGC/Tabs/FestivalSubCategory/basicDetails";
import BlogHi from "./components/Admin/Blog/BlogHi";
import BlogEn from "./components/Admin/Blog/BlogEn";
import Wellness from "./components/Admin/Wellness";
import TrendingBlogs from "./components/Admin/Blog/TrendingBlog";
import UploadImage from "./components/Others/UploadImage";

// CSS
import "./assets/css/home.css";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const routerPath = window.location.pathname;
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-accounts-list" element={<UserAccountList />} />
          <Route path="/single-user-details" element={<UserAccountDetails />} />
          <Route path="/report-accounts" element={<ReportAccounts />} />
          <Route path="/report-posts" element={<ReportPosts />} />
          <Route path="/report-shorts" element={<ReportShorts />} />
          <Route path="/report-hash-tags" element={<ReportHashTags />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/festivals" element={<Festivals />} />
          <Route path="/festival-category-update" element={<FestivalscategoryUpdate />} />
          <Route path="/add-festivals" element={<AddFestivals />} />
          <Route path="/rituals" element={<Rituals />} />
          <Route path="/vratKatha" element={<VratKatha />} />
          <Route path="/mantra" element={<Mantra />} />
          <Route path="/chalisa" element={<Chalisa />} />
          <Route path="/aarti" element={<Aarti />} />
          <Route path="/hindu-festivals" element={<HinduFestivals />} />
          <Route path="/temples" element={<Temples />} />
          <Route path="/vrat-katha-video" element={<VratKathaVideo />} />
          <Route path="/popular-video" element={<PopularVideo />} />
          <Route path="/prayers-mantras" element={<PrayersMantras />} />
          <Route path="/more-video" element={<More />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/videos" element={<Video />} />
          <Route path="/single-category-details" element={<SingleCategoryDetails />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/report-account-detail" element={<ReportAccountDetail />} />
          <Route path="/sub-festival" element={<FestivalSub />} />
          <Route path="/add-sub-festivals" element={<AddFestivalSub />} />
          <Route path="/aarti-sub-category" element={<AartiSubCat />} />
          <Route path="/text-editor" element={<TextEditor />} />
          <Route path="/play-video" element={<PlayVideo />} />
          <Route path="/new-user-list" element={<NewUserList />} />
          <Route path="/live-darshan" element={<LiveDarshan />} />
          <Route path="/edit-live-darshan" element={<EditLiveDarshan />} />
          <Route path="/add-new-live-darshan-category" element={<AddLiveDarshan />} />
          <Route path="/live-festival" element={<LiveFestival />} />
          <Route path="/edit-seo" element={<EditSeoFestival />} />
          <Route path="/add-seo-festival" element={<AddSeoFestival />} />
          <Route path="/catagory-seo" element={<CatagorySeo />} />
          <Route path="/without-seo" element={<WithOutSeo />} />
          <Route path="/edit-video" element={<EditVideo />} />
          <Route path="/add-video" element={<AddVideo />} />
          <Route path="/chant-life" element={<ChantLife />} />
          <Route path="/edit-chant-life" element={<EditChantLife />} />
          <Route path="/add-chant-life-post" element={<AddChantLifePost />} />
          <Route path="/basic-details" element={<BasicDetail />} />
          <Route path="/blog-en" element={<BlogEn />} />
          <Route path="/blog-hi" element={<BlogHi />} />
          <Route path="/wellness" element={<Wellness />} />
          <Route path="/trending-blogs" element={<TrendingBlogs />} />
          <Route path="/test" element={<Test />} />
          <Route path="/form" element={<Form />} />
          <Route path="/upload-image" element={<UploadImage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
