import React from 'react';
import ChartComponent from "./ChartComponent";

const Home = (props) => {
  const token = localStorage.getItem("token");
  
  if (token && token != 'undefined' ) {
    return (
      <>
        <div className="graphContainer">
          <div className='App'>
            <ChartComponent data={props.data} />
          </div>
          <div>
            <span className='radioColor' style={{ backgroundColor: 'rgb(242, 116, 19)' }}></span><span className='gendeR'>{props.data[0].value + "% "}Male</span><br />
            <span className='radioColor' style={{ backgroundColor: 'rgba(242, 116, 19, 0.34)' }}></span><span className='gendeR'>{props.data[1].value + "% "}Female</span><br />
            <span className='radioColor' style={{ backgroundColor: 'black' }}></span><span className='gendeR'>{props.data[2].value + "% "}Other</span>
          </div>
        </div>
      </>
    )
  } else {
    window.location.href = '/'
  }
}

export default Home