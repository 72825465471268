import React from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import img from "../../../assets/img/logo.png";
import deviceStorage from "../../../config/deviceStorage";

const index = (props) => {
  return (
    <section className="sidebar">
      <div>
        <img src={img} alt="profilePic" />
        <h4>Hello</h4>
        <p>Welcome back</p>
        <h4>Rgyan Admin</h4>

        <div className="dropdownBox">
          <Link
            to="/dashboard"
            className="anchor"
            id={props.dashboard ? props.dashboard : " "}
          >
            Dashboard
          </Link>
          <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong id={props.ugc ? props.ugc : " "}>UGC</strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/user-accounts-list">
                    Accounts
                  </DropdownItem>
                  <DropdownItem tag="a" href="/categories">
                    Category
                  </DropdownItem>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          Report
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/report-accounts">
                            Accounts
                          </DropdownItem>
                          <DropdownItem tag="a" href="/report-hash-tags">
                            Hashtags
                          </DropdownItem>
                          <DropdownItem tag="a" href="/report-posts">
                            Posts
                          </DropdownItem>
                          <DropdownItem tag="a" href="/report-shorts">
                            Shorts
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>
          <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong id={props.pgc ? props.pgc : " "}>PGC</strong>
                </DropdownToggle>
                <DropdownMenu>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          Blogs
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/blog-en">
                            {" "}
                            English Blog{" "}
                          </DropdownItem>
                          <DropdownItem tag="a" href="/blog-hi">
                            {" "}
                            Hindi Blog
                          </DropdownItem>
                          <DropdownItem tag="a" href="/trending-blogs">
                            {" "}
                            Trending Blog
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                  <DropdownItem tag="a" href="/chant-life">
                    Chant Life
                  </DropdownItem>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          Devotion
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/rituals">
                            Rituals
                          </DropdownItem>
                          <DropdownItem tag="a" href="/vratKatha">
                            VratKatha
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                  <DropdownItem tag="a" href="/festivals">
                    Festivals
                  </DropdownItem>
                  <DropdownItem tag="a" href="/live-darshan">
                    Live Darshan
                  </DropdownItem>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          SEO
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/catagory-seo">
                            With SEO
                          </DropdownItem>
                          <DropdownItem tag="a" href="/without-seo">
                            {" "}
                            Without SEO
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                  <DropdownItem tag="a" href="/videos">
                    Videos
                  </DropdownItem>
                  <DropdownItem tag="a" href="/wellness">
                    {" "}
                    Wellness
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>

          <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong id={props.other ? props.other : " "}>Other</strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/upload-image">
                    Upload Image
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>
        </div>
      </div>
      <div>
        <button className="logOutButton" onClick={deviceStorage.logOut}>
          LOG OUT
        </button>

        <div className="btm-rgyan">
          <p className="rgyan">Rgyan</p>
          <p className="para">Spiritual Social Network</p>
        </div>
      </div>
    </section>
  );
};

export default index;
