import React from 'react';
import Tabs from '../../../Tabs/Tabs';
import RitualsAllCategory from '../../Divotion/RitualsAllCat'

const RitualsCategory = () => {

  let Astrologys  = [
    {id:1, name: "Brahmin" }, {id:2, name: "Vaishya" }, {id:3, name: "Kshatriya" }, {id:4, name: "Shuda" }]
  


  return (

    <section className='postCountIV'>
      <Tabs>
        {Astrologys && Astrologys.length>0 && Astrologys.map((items, index) => {
          return <div key={index} label={items.name} >
            <RitualsAllCategory data={items.id}/>
          </div>

        })}
      </Tabs>
    </section>


    






    // <section className='postCountIV'>
    //   <Tabs>
    //     <div label="Brahmin">
    //       <Astrology />
    //     </div>
    //     <div label="Vaishya">
    //       <Astrology />
    //     </div>
    //     <div label="Kshatriya">
    //       <Astrology />
    //     </div>
    //     <div label="Shuda">
    //       <Astrology />
    //     </div>
    //   </Tabs>
    // </section>
  )
}

export default RitualsCategory