import React from 'react';
import Sidebar from '../../Side';
import Navigation from '../../Navigation';
import AddCategoryForm from './addSubCategoryForm'

const Index = (props) => {
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("user")
    const pageTitle = 'Add Category';
    const active = 'active'
    if(token){
        return (
            <>
                 <div className="main-parentcontainer">
                    <div className="grid-parent">
                    <Sidebar data={name} ugc={active} />
                        <div className='grid-child'><Navigation data={pageTitle} />
                        <AddCategoryForm /></div>
                    </div></div>
               
                
            </>
        )
        }else{
            window.location.href = '/';
        }
    }

export default Index