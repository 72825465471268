import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import styles from "../../../assets/css/styles.css";
import Sidebar from "../Side";
import Tabs from "../Tabs/Tabs";

const url = process.env.REACT_APP_BASE_URL;

const C = (props) => {
  let level = props.data.type;
  const [chant, setChant] = useState([]);

  const Chant = async (value) => {
    level = value;
    await axios
      .get(url + "/admin/chant-life-data?parentId=" + level, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setChant(res.data.data);
      })
      .catch((err) => {
        console.log("Error::::::::", err);
      });
  };

  useEffect(() => {
    Chant(level);
  }, []);
  return (
    <>
      <select
        name="data.name_en"
        onChange={(e) => {
          Chant(e.target.value);
        }}
        style={{ width: "100%" }}
      >
        {props.data.subCategory.map((e, i) => {
          return (
            <option style={{ cursor: "pointer" }} key={i} value={e.id}>
              {e.name_en}
            </option>
          );
        })}
      </select>
      <table
        class="table table-striped table-bordered"
        style={{
          borderRadius: "3px",
          padding: "5px",
          backgroundColor: "white",
          width: "fitContent",
          // border:"2px solid black"
        }}
      >
        {chant &&
          chant.length > 0 &&
          chant.map((ele, ind) => {
            return (
              <tr
                className={styles.SpButton}
                key={ind + 1}
                style={{ borderWidth: "0px" }}
              >
                <Button
                  id={ele.category.split(" ")[0] + ind}
                  style={{
                    display: "inline-block",
                    height: "50px",
                    borderWidth: "0px",
                    color: "black",
                    background: "none",
                    width: "100%",
                    textalign: "center",
                    padding: "0px",
                  }}
                  colspan="2"
                >
                  <td
                    style={{
                      padding: "10px",
                      width: "100%",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    {ele.category}
                    <FontAwesomeIcon
                      id={ind + "downnArrow"}
                      icon={faAngleDown}
                      style={{ float: "right", fontSize: "15px" }}
                    />
                  </td>
                </Button>
                <UncontrolledCollapse
                  toggler={`#${ele.category.split(" ")[0] + ind}`}
                >
                  <Card>
                    <CardBody>
                      <table
                        class="table table-striped table-bordered"
                        style={{
                          borderRadius: "3px",
                          padding: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <tbody>
                          {ele.post.map((e, inx) => {
                            return (
                              <tr key={inx}>
                                <td>{inx + 1}</td>
                                <td>{e.title_en}</td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    props.HandleRedirect(e);
                                  }}
                                >
                                  Edit
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </tr>
            );
          })}
      </table>
    </>
  );
};
const Index = () => {
  const [rgyan, setRgyan] = useState([]);
  const [display, setDisplay] = useState();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [up, setUp] = useState(false);
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Chant Life";
  const active = "active";
  const HandleRedirect = (value) => {
    navigate("/edit-chant-life", {
      state: value,
    });
  };
  const chantLifeCategoryData = async (value) => {
    await axios
      .get(url + "/admin/chant-life-category-data", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setRgyan(res.data.data);
          setIsOpen(true);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
      });
  };

  const DownArrow = (index) => {
    const down = document.getElementById(`${index}downArrow`);
    if (down.style.transform == "rotate(180deg)") {
      down.style.transition = "all 0.3s ease-out";
      down.style.transform = "rotate(0deg)";
    } else if (down !== null) {
      down.style.transition = "all 0.3s ease-out";
      down.style.transform = "rotate(180deg)";
    }
  };
  const DownnArrow = (index) => {
    const down = document.getElementById(`${index}downnArrow`);
    if (down.style.transform == "rotate(180deg)") {
      down.style.transition = "all 0.3s ease-out";
      down.style.transform = "rotate(0deg)";
    } else if (down !== null) {
      down.style.transition = "all 0.3s ease-out";
      down.style.transform = "rotate(180deg)";
    }
  };
  useEffect(() => {
    chantLifeCategoryData();
  }, []);

  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              {" "}
              <Navigation data={pageTitle} />
              <section
                className="postCount"
                style={{ overflow: "scroll", marginTop: "5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <div>
                    <span
                      className="lableCategory"
                      style={{ width: "100%", height: "100vh" }}
                    >
                      {" "}
                    </span>
                  </div>
                </div>
                <h5
                  className="col-lg-12 p-2"
                  style={{
                    backgroundColor: "#D3D3D3",
                    color: "black",

                    textAlign: "center",
                    borderRadius: "10px",
                    fontSize: "30px",
                  }}
                >
                  {" "}
                  Chant Life
                </h5>

                {isOpen && (
                  <Tabs>
                    {rgyan &&
                      rgyan.length > 0 &&
                      rgyan.map((data, index) => {
                        data.type =
                          index == 0
                            ? 96
                            : index == 1
                            ? 302
                            : index == 2
                            ? 305
                            : 473;
                        return (
                          <div
                            key={index}
                            label={data.category}
                            onClick={() => {
                              DownArrow(index);
                            }}
                          >
                            <C data={data} HandleRedirect={HandleRedirect} />
                          </div>
                        );
                      })}
                  </Tabs>
                )}
              </section>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};
export default Index;
