import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroller";
import qs from "qs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faThumbsUp, faShare, faCommentDots, } from '@fortawesome/free-solid-svg-icons';
const url = process.env.REACT_APP_BASE_URL;


const Posts = (props) => {
    const [userPost, setUserPosts] = useState([]);
    const [success, setSuccess] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");
    const [hasPosts, setHasPosts] = useState(true);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    let data = props.data;
    useEffect(() => {
        response();
    }, []);

    const response = async () => {
        await axios.get(`${url}/admin/get-user-content`, {

            params: {
                skip: skip,
                limit: limit,
                "userId": data.id,
                "type": "post"
            },
            headers: {
                'authorization': `Bearer ${localStorage.getItem("token")}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setUserPosts([...userPost, ...res.data.data])
            setLimit(limit + 10)
            setSkip(skip + 10)
            if (res.data.data.length < 10) {
                setHasPosts(false)
            }
        }).catch(err => console.log("Error::::::::", err))
    }
    async function handleDisable(id, type) {
        let dataaa = {
            "postId": id,
            "status": type,
            "type": "post",
        }
        const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/admin/change-post-visibility`,
            dataaa, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }
        });
        if (res.data.status === "success") {
            setIsSuccess(true);
            setIsError(false);
            setSuccess(
                "Post has been successfully updated"
            );
            setTimeout(() => {
                setIsSuccess(false);
                setIsError(false);
                setSuccess("");
                window.location.reload();
            }, 3000);
        } else {
            setIsSuccess(false);
            setIsError(true);
            setError(
                "Something went wrong"
            );
        }
    }
    return (
        <div className='' style={{ width: '100%', height: '100vh', overflow: 'scroll' }}>
            {isError && (
                <div className={"alert alert-danger"}>
                    {error}
                </div>
            )}
            {isSuccess && (
                <div className={"alert alert-success"}>
                    {success}
                </div>
            )}
            <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                    response()
                }}
                hasMore={hasPosts}
                loader={<div key={0} >Loading</div>}
                useWindow={false}
            >
                {
                   userPost && userPost.length> 0 && userPost.map((data, index) => {
                        return <div key={index} style={{ backgroundColor: '#D9D9D9' }}>
                            <Card style={{ width: '100%', borderRadius: '0px' }}>
                                <Card.Body>
                                    <div style={{ display: 'flex', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '5px' }}>
                                        {data.posts_media.length > 0 && (data.posts_media[0].media_type === "image" ? <div style={{ height: '180px' }}><Card.Img variant="top" src={data.posts_media[0].url} alt='Img' style={{
                                            width: '250px', borderRadius: '5px 0px 0px 5px',
                                            height: '100%', objectFit: 'cover'
                                        }} /></div> :
                                            <div style={{ height: '180px' }}>
                                                <video controls="controls" style={{ width: '250px', borderRadius: '5px 0px 0px 5px', height: '100%', objectFit: 'cover' }}>
                                                    <source src={data.posts_media[0].url} type="video/mp4" />
                                                </video>
                                            </div>)}
                                        <div style={{ padding: '15px', borderRadius: '0px 10px 10px 0px', }}>
                                            <Card.Title>PostId {data.id} {data.post_categories[0].categories[0].name ? <span style={{ backgroundColor: `${data.post_categories[0].categories[0].color}`, borderRadius: '20px', padding: '5px 15px', fontWeight: '500', fontSize: '15px' }}>{data.post_categories[0].categories[0].name}</span> : <span>No Category</span>}</Card.Title>
                                            <Card.Text style={{ height: '60px', overflow: 'scroll' }}>
                                                {data.post_content === '' ? 'No Content' : data.post_content}
                                            </Card.Text>

                                            <div style={{ width: '780px', marginBottom: '0px' }}>
                                                <span className="" style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faEye} className="mr-2" />{' ' + data.view_counts}</span>
                                                <span className="" style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCommentDots} className="mr-2" />{' ' + data.commentCount}</span>
                                                <span className="" style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faThumbsUp} className="mr-2" />{' ' + data.likeCount}</span>
                                                <span className="" style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faShare} className="mr-2" />{' ' + data.shareCount}</span>
                                                {data.is_post ? <Button variant="danger" className='postButton' style={{ float: 'right' }} onClick={() => { handleDisable(data.id, false) }}>Disable</Button> : <Button variant="success" className='postButton' style={{ float: 'right' }} onClick={() => { handleDisable(data.id, true) }}>Enable</Button>}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                    }
                    )
                }
            </InfiniteScroll>
        </div>
    )
}

export default Posts
