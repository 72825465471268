import React from 'react';
import axios from 'axios'
import Tabs from '../Tabs/Tabs';
import WithOutSeoCategory from '../../Admin/WithOutSeo/WithOutSeoCatagory'
import Navigation from '../Navigation';
import Sidebar from '../Side';

const URL = process.env.REACT_APP_BASE_URL;

const ReportList = () => {
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("user")
    const pageTitle = 'Without SEO ';
    const active = 'active';

    if (token && token != 'undefined' ) {
        const data = [
            { name: 'Ritual Category ', type: 1 },
            { name: 'Ritual Post', type: 2 },
            { name: 'Temple', type: 3 },
            { name: 'Varatkatha Category ', type: 4 },
            { name: 'Varatkatha', type: 5 },
            { name: 'English Blog', type: 6 },
            { name: 'Hindi Blog', type: 7 },
            { name: 'Festivals', type: 8 },
        ]
        return (
            <>
                
                
<div className="main-parentcontainer">
                    <div className="grid-parent"> <Sidebar data={name} pgc={active} /><div className='grid-child'>
                    <Navigation data={pageTitle} />
                <section className='postCountIV'>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div>
                            <span className='lableCategory' style={{ width: "100%", height: "100vh" }} > </span>
                        </div>
                    </div> */}
                   
                    <div className='seo-grid'>
                    <h5
                        className="col-lg-12 p-2"
                        style={{
                            backgroundColor: '#D3D3D3',
                            color: 'black',

                            textAlign: "center",
                            borderRadius: "10px",
                            fontSize: "30px"
                        }}
                    > Without SEO Catagory
                    </h5>
                    <Tabs>
                        {data && data.length > 0 && data.map((data, index) => {

                            return <div key={index} label={data.name}>


                                <WithOutSeoCategory data={data.type} name={data.name} />

                            </div>
                        })}
                    </Tabs>
                    </div>

                    
                </section>
                    </div></div></div>
               
               
            </>
        )
    }
    else {
        window.location.href = '/'
    }
}

export default ReportList;
