import React, { Component, useState } from "react";
import { Field, useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
import Navigation from "../../../Navigation";
import Sidebar from "../../../Side";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

// import axios from 'axios';
const url = process.env.REACT_APP_BASE_URL;

function Form(props) {
  const history = useLocation();
  const router = useNavigate();
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const onDismiss = () => setVisible(false);
  const [data, setData] = useState(history.state);
  const navigate = useNavigate();

  const HandleRedirect = (type) => {
    navigate("/add-video", {
      state: {
        type: type,
        category_id: data.category_id,
      },
    });
  };
  const AddSeoFestival = (values) => {
    fetch(`${url}/admin/edit-rgyan-tv`, {
      params: {
        type: props.data,
        categoryname: props.categoryname,
      },
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status == "success") {
            setIsSuccess(true);
            setMessage(result.msg);
            setData(result.data[0]);

            setTimeout(() => {
              setIsSuccess(false);
              setMessage("");
            }, 3000);
          }
        },
        (err) => {
          console.log("Error::::::::", err);
          setIsError(true);
          setError("Something went wrong. Try again");
        }
      );
  };

  const formik = useFormik({
    initialValues: {
      id: data.id,
      description_en: data.description_en,
      description_hi: data.description_hi,
      title_en: data.title_en,
      title_hi: data.title_hi,
      video_id: data.video_id,
      status: data.status,
    },
    onSubmit: (values) => {
      AddSeoFestival(values);
    },
  });
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Video";
  const active = "active";
  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <section className="postCount" style={{ overflow: "scroll" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      color: "black",

                      textAlign: "center",

                      borderRadius: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <h3>Edit {data.componentName} Video</h3>
                  </div>
                  <div className="edit-vedio">
                    <button
                      style={{
                        cursor: "pointer",
                        border: "2px solid gray",
                        borderRadius: "10px",
                        justifyContent: "flex-end",
                        marginRight: "0px",
                      }}
                      onClick={() => {
                        HandleRedirect(data, data.type);
                      }}
                    >
                      {" "}
                      ADD New Video{" "}
                      <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}
                    </button>
                  </div>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                 
                >
                  {isSuccess && (
                    <div className="row">
                      <div className="col-lg-6">
                        {isSuccess && (
                          <Alert
                            color="info"
                            isOpen={visible}
                            toggle={onDismiss}
                          >
                            {message}
                          </Alert>
                        )}
                      </div>
                    </div>
                  )}

                  {/* <div
                  className="row row-bg-2 edit-vedio-form"
                  style={{ padding: "7px", marginTop: "30px" }}
                >
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className=" col-form-label">
                          {" ID: "}
                        </label>
                        <div className="">
                          <input
                            type="text"
                            name="id"
                            onChange={formik.handleChange}
                            value={formik.values.id}
                            disabled={true}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className="col-md-4 col-form-label">
                          {"Video ID: "}
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            type="text"
                            name="video_id"
                            onChange={formik.handleChange}
                            value={formik.values.video_id}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className="col-md-4 col-form-label">
                          {"Title English: "}
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            type="text"
                            name="title_en"
                            onChange={formik.handleChange}
                            value={formik.values.title_en}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className="col-md-4 col-form-label">
                          {"Title Hindi: "}
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            type="text"
                            name="title_hi"
                            // onClick={formik.values.meta_description}
                            onChange={formik.handleChange}
                            value={formik.values.title_hi}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className="col-md-4 col-form-label">
                          {"Description English: "}
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            type="text"
                            name="description_en"
                            onChange={formik.handleChange}
                            value={formik.values.description_en}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className="col-md-4 col-form-label">
                          {"Description Hindi: "}
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            type="text"
                            name="description_hi"
                            onChange={formik.handleChange}
                            value={formik.values.description_hi}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="">
                        <label className="col-md-4 col-form-label">
                          {"Status : "}
                        </label>
                        <div className="col-md-8 mb-4">
                          <select
                            name="status"
                            onChange={formik.handleChange}
                            style={{ width: "100%" }}
                          >
                            <option value={"Select"}>Select</option>
                            <option value={"false"}>False</option>
                            <option value={"true"}>True</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

              
                    <div className="form">
                      <div className="each-input">
                        <label>ID*</label>
                        <input
                        type="text"
                           id="slect-month"
                            name="id"
                            onChange={formik.handleChange}
                            value={formik.values.id}
                            disabled={true}
                            style={{ width: "100%" }}
                          />
                      </div>

                      <div className="each-input">
                        <label>Vedio Id*</label>
                        <input
                            type="text"
                            name="video_id"
                            onChange={formik.handleChange}
                            value={formik.values.video_id}
                            style={{ width: "100%" }}
                          />
                      </div>

                      <div className="each-input">
                        <label>Title English*</label>
                        <input
                            type="text"
                            name="title_en"
                            onChange={formik.handleChange}
                            value={formik.values.title_en}
                            style={{ width: "100%" }}
                          />
                      </div>

                      <div className="each-input">
                        <label>Title Hi</label>
                        <input
                            type="text"
                            name="title_hi"
                            // onClick={formik.values.meta_description}
                            onChange={formik.handleChange}
                            value={formik.values.title_hi}
                            style={{ width: "100%" }}
                          />
                      </div>

                      <div className="each-input">
                        <label>Description En</label>
                        <input
                            type="text"
                            name="description_en"
                            onChange={formik.handleChange}
                            value={formik.values.description_en}
                            style={{ width: "100%" }}
                          />
                      </div>
                      <div className="each-input">
                        <label>Description Hi</label>
                        <input
                            type="text"
                            name="description_hi"
                            onChange={formik.handleChange}
                            value={formik.values.description_hi}
                            style={{ width: "100%" }}
                          />
                      </div>

                      {/*    <div className="each-input">
    <label>Month*</label>
    <Select
      defaultValue={defaultvalue}
      onChange={(e) => {
        setselmonth(e.value);
      }}
      options={selectOption}
    />
  </div> */}


                      <div className="each-input">
                        <label>Status*</label>
                        <select
                            name="status"
                            onChange={formik.handleChange}
                            style={{ width: "100%" }}
                          >
                            <option value={"Select"}>Select</option>
                            <option value={"false"}>False</option>
                            <option value={"true"}>True</option>
                          </select>
                      </div>
                    </div>
                   
                
                  <div className="submit-btn" style={{ marginTop:"12px" , textAlign: "center" }}>
                    <button
                      type="submit"
                      className="btn mb-2"
                      style={{
                        background:
                          "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                        color: "white",
                        padding: "10px",
                        width: "100px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>{" "}
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Form;
